import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "protocol-policy_content" }

import { onMounted, ref } from "vue";
import { IonPage, modalController } from "@ionic/vue";
import PageWhiteBackground from "@/components/PageWhiteBackground.vue";
import { getPrivacy, getUserAgreement } from "@/js/userAgreementContent";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProtocolAndPolicy',
  props: {
  type: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const obj = ref({});
onMounted(() => {
  if (localStorage.getItem("STORAGE_KEY_OEM")) {
    let color = JSON.parse(
      localStorage.getItem("STORAGE_KEY_OEM")!
    ).OEM_DETAIL_KEY_BACKGROUND_COLOR;
    document.documentElement.style.setProperty("--brand_background_color", color);
  }
  if (props.type == "userAgreement") {
    obj.value = getUserAgreement();
  } else {
    obj.value = getPrivacy();
  }
});

async function routerBack() {
  await modalController.dismiss();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), { class: "protocol-policy" }, {
    default: _withCtx(() => [
      _createVNode(PageWhiteBackground, {
        title: obj.value.title,
        "router-type": "modal",
        onRouterBack: routerBack
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(obj.value.content), 1)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }))
}
}

})