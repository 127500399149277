import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "essay-modal flex-column" }
const _hoisted_2 = { class: "essay-modal_ctt flex-column" }
const _hoisted_3 = {
  key: 0,
  class: "essay-modal_exercise flex-column"
}
const _hoisted_4 = { class: "flex-row justify-between" }
const _hoisted_5 = { class: "questions-index flex-row align-center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "clock-submit" }
const _hoisted_8 = {
  key: 0,
  class: "clock-submit_countdown mr-2"
}
const _hoisted_9 = {
  key: 0,
  class: "countdown-time common-color"
}
const _hoisted_10 = {
  key: 1,
  class: "countdown-time common-color"
}
const _hoisted_11 = { class: "question-title_title weight-700" }
const _hoisted_12 = { class: "question-title_answer" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 1,
  class: "essay-modal_exercise flex-column"
}
const _hoisted_19 = { class: "exercise-questions flex-column mt-5" }
const _hoisted_20 = { class: "question-title_option" }
const _hoisted_21 = { class: "question-title_title weight-700" }
const _hoisted_22 = { class: "exercise-questions-analyze_title weight-600 mb-2" }
const _hoisted_23 = { class: "flex-row flex-wrap" }
const _hoisted_24 = { class: "exercise-questions-analyze_text mr-5" }
const _hoisted_25 = { class: "common-color" }
const _hoisted_26 = { class: "exercise-questions-analyze_text" }
const _hoisted_27 = { class: "mt-2" }
const _hoisted_28 = { class: "exercise-questions-analyze_text" }
const _hoisted_29 = {
  key: 2,
  class: "essay-modal_glossary"
}
const _hoisted_30 = { class: "essay-modal_glossary-grid" }
const _hoisted_31 = { class: "essay-modal_glossary-text text-center" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = {
  key: 3,
  class: "display_content"
}
const _hoisted_34 = {
  key: 4,
  class: "display_content"
}
const _hoisted_35 = {
  key: 5,
  class: "display_content"
}

import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import http from "@/js/http";
import { IonButton, IonCol, IonGrid, IonRow, IonSelect, modalController } from "@ionic/vue";
import Text from "@/components/MarkdownText.vue";
import store from "@/js/store";
import { useExerciseCountdownStore } from "@/hooks/useExerciseCountdown";
import { useCurrentStepStore } from "@/hooks/useCurrentStepStore";
import { openMsgToast, openProblematicAlert } from "@/views/AIGenerate/openModal";
import { useGlossaryStore } from "@/hooks/useGlossary";
import { getLocalStorage } from "@/views/AIGenerate/smartReading/commonMethod";
import { useEssayGenerateContentStore } from "@/hooks/useEssayGenerateContentStore";
import { default_exercise_answer } from "@/views/AIGenerate/smartReading/defaultData";
import PageDialog from "@/components/PageDialog.vue";
import { useStorage } from "@vueuse/core/index";


export default /*@__PURE__*/_defineComponent({
  __name: 'EssayGenerateModal',
  props: ["word", "type", "title", "questions", "exerciseObj", "isFullscreen"],
  emits: [
  "closeCurrentHalfModal",
  "openModalFullScreen",
  "closeModalHalfScreen",
  "setQuestionRefer",
  "changeAudioStatus",
],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const countdownStore = useExerciseCountdownStore();
const contentStore = useEssayGenerateContentStore();
const currentStepStore = useCurrentStepStore();
const glossaryStore = useGlossaryStore();
const route = useRoute();

const type = ref();
const selectWord = ref();
const studentInfo = ref();
const glossaryArr: any = ref([]);
const modalIsFold = ref("expand");
const currentExeValue = ref({ type: "exercise", title: "Questions" });
let answerArr: any = [];
let arr = JSON.parse(JSON.stringify(default_exercise_answer));
const defaultArr = ["", "", "", "", ""];
const answerIndex = ref(1); // 当前答题的序号
const answerClass = ref(""); // 当前答题的样式
const questionIndexClass = ref(""); // 当前答题的样式
const exerciseProcess = ref(0);
const checkValues = ref(default_exercise_answer);
const isFullscreen = ref(false);
const twiceCheckValues = ref<any>([]);
const glossaryList = useStorage("GLOSSARY_ARRAY", [], localStorage);
const knowledges = ref("");

function clickTitleBtn(reference: string, content: number) {
  if (reference) {
    const list = document.querySelectorAll(".ai-article-paragraph");
    reference = reference.replace("一", "1");
    reference = reference.replace("二", "2");
    reference = reference.replace("三", "3");
    reference = reference.replace("四", "4");
    reference = reference.replace("五", "5");
    reference = reference.replace("六", "6");
    reference = reference.replace("七", "7");
    reference = reference.replace("八", "8");
    reference = reference.replace("九", "9");
    reference = reference.replace("十", "10");
    reference = reference.replace("最后1段", `段落${list.length}`);
    console.log(reference);
    const refer = reference.match(/[0-9]+/g);
    emit("setQuestionRefer", refer);
  } else {
    emit("setQuestionRefer");
  }
}

function changeAudioStatus(status: string) {
  emit("changeAudioStatus", status);
}

function openModalFullScreen() {
  emit("openModalFullScreen");
}

function closeModalHalfScreen() {
  emit("closeModalHalfScreen");
}

function initCheckedValues() {
  const obj = contentStore.getStorageData();

  if (!obj) {
    return;
  }

  if (currentStepStore.currentStep === 2) {
    // 泛读答题
    answerArr = obj.answer ? obj.answer.split(",") : defaultArr;
  } else if (currentStepStore.currentStep === 7) {
    // 再次答题
    answerArr = obj.againAnswer ? obj.againAnswer.split(",") : defaultArr;
  }

  arr = arr.map((i: Record<string, any>, index: number) => {
    i["value"] = answerArr[index];
    i["id"] = index + 1;
    return i;
  });
  exerciseProcess.value = (arr || []).filter((item: any) => item.value).length;

  return arr;
}

async function checkQuestionAnswer(idx: number, answer: string) {
  if (checkValues.value[idx]["value"] !== answer) {
    checkValues.value[idx]["value"] = answer;
  } else {
    checkValues.value[idx]["value"] = "";
  }
  exerciseProcess.value = (checkValues.value || []).filter((item: any) => item.value).length;
  console.log(exerciseProcess.value);
  if (checkValues.value[idx]["value"]) {
    if (idx === 4) {
      const modal = await modalController.create({
        component: PageDialog,
        componentProps: {
          message: "是否提交答案并跳转到下一流程？",
          type: "hasCancel",
        },
        cssClass: "center_modal_controller_class3",
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data === "NEXT") {
        await submitAnswer();
      }
    } else {
      chooseQuestionIndex(idx + 2);
    }
  }
}

const textExplain = computed(() => {
  const titleReg = /^(?<=.*)(.*)(?=\s)/g;
  const matchStr = (props.exerciseObj?.textCn || "").match(titleReg) || "";
  /*const content = (props.exerciseObj?.textCn || '').replace(matchStr[0], ($1, $2: any) => {
    return `<strong style="display: inline-block; width: 100%;text-align: center">${matchStr[0] + '\n'}</strong>`;
  })*/

  let content = (props.exerciseObj?.textCn || "").replace(matchStr[0], "");

  content = content
    .replace(/[*]{2}(.+?)[*]{2}/gim, ($1, $2: any) => {
      return `<strong style="color: #0BBB7D;text-align: center">${$2}</strong>`;
    })
    .replace(/^\s+/, "");
  content = "<div>全文大意：</div>" + content;
  return content;
});

async function didDismiss() {
  modalIsFold.value = "fold";
  await modalController.dismiss({
    value: checkValues.value,
    step: currentStepStore.currentStep,
    fold: modalIsFold.value,
  });
}

// 先调取接口,再删除
function deleteWordFromGlossary(id: number, item: Record<string, any>) {
  const appointment = store.getAppointment();
  if (id) {
    http
      .get("/vocabulary/deleteVocabularyPersonal?id=" + id)
      .then((res) => {
        if (res.data.success) {
          openMsgToast("成功删除");
          glossaryStore.getGlossaryList(appointment.studentId, appointment.courseTypeId);
          removeGlossaryFromLocal(item.word);
        } else {
          openMsgToast("删除失败");
        }
      })
      .catch((e) => {
        throw e;
      });
  } else {
    openMsgToast("不合法的单词");
  }
}

function removeGlossaryFromLocal(word: string) {
  let arr: any = [];
  for (let i = 0; i < glossaryList.value.length; i++) {
    if (word !== glossaryList.value[i].word) {
      arr.push(glossaryList.value[i]);
    } else {
      const glossaryDom: any = document.querySelector("#" + glossaryList.value[i].id);
      glossaryDom.style.backgroundColor = "transparent";
    }
  }
  console.log(arr);
  glossaryList.value = arr;
}

async function closeCurrentHalfModal() {
  console.log("关闭弹窗");
  emit("closeCurrentHalfModal");
}

// 修改答案后重新获取AI智阅相关内容
async function getNewGenerateContent() {
  let resData;
  const appointment = store.getAppointment();
  const data = await http.get(`/Ai/queryEduAiAppointment?aptmId=${appointment.aptmId}`);
  if (data?.data.code === 200) {
    resData = data.data.data;
    useEssayGenerateContentStore().storageIdResContent(resData);
  }
  return resData;
}

function chooseQuestionIndex(idx: number) {
  answerIndex.value = idx;
  const question = props.exerciseObj?.question || "[]";
  if (question) {
    answerClass.value = `answer-index${answerIndex.value}`;
    questionIndexClass.value = `index-nav${answerIndex.value}`;
  }
}

function nextTopic() {
  const question = props.exerciseObj?.question || "[]";
  if (question) {
    const len = JSON.parse(question).length;
    if (answerIndex.value <= len) {
      answerIndex.value++;
      answerClass.value = `answer-index${answerIndex.value}`;
      questionIndexClass.value = `index-nav${answerIndex.value}`;
    }
  }
}

function previousTopic() {
  const question = props.exerciseObj?.question || "[]";
  if (question) {
    const len = JSON.parse(question).length;
    if (answerIndex.value <= len) {
      answerIndex.value--;
      answerClass.value = `answer-index${answerIndex.value}`;
      questionIndexClass.value = `index-nav${answerIndex.value}`;
    }
  }
}

// 提交答案
async function submitAnswer() {
  const totalLen = checkValues.value ? checkValues.value.length : 0;
  const fillInputLen = (checkValues.value || []).filter((item: any) => item.value).length;
  if (fillInputLen !== totalLen) {
    const alertParams = {
      message: `剩余${totalLen - fillInputLen}题未作答,是否提交？`,
      buttons: [
        {
          text: "取消",
          role: "cancel",
        },
        {
          text: "确认",
          role: "confirm",
          handler: async () => {
            postAnswerIntoDb();
            countdownStore.terminateCountdownFun(); // 终止倒计时
            await openMsgToast("成功提交答案！");
            await modalController.dismiss().catch((e) => console.log(e));
            await afterSubmitAnswer();
          },
        },
      ],
    };
    await openProblematicAlert(alertParams);
  } else {
    postAnswerIntoDb();
    countdownStore.terminateCountdownFun(); // 终止倒计时
    await openMsgToast("成功提交答案！");
    await modalController.dismiss().catch((e) => console.log(e));
    await afterSubmitAnswer();
  }
}

async function afterSubmitAnswer() {
  await getNewGenerateContent();
  currentStepStore.controlStepData(); // 处理流程执行数据
  currentStepStore.handleProgressTitleNameArr(); // 动态展示流程名称
  await currentStepStore.handleProgressStepEvent(closeCurrentHalfModal); // 处理每个流程相关的事件
}

function postAnswerIntoDb() {
  const answerData = (checkValues.value || []).map((item: any) => item.value).join(",");
  const params = {
    eduAiAppointmentReadId: props.exerciseObj?.eduAiAppointmentReadId,
  };
  if (currentStepStore.currentStep === 2) {
    // 泛读答题
    http
      .post("/Ai/saveThreeEduAiAppointmentRead", {
        ...params,
        answer: answerData,
      })
      .then((res) => {
        console.log(res);
      });
  } else if (currentStepStore.currentStep === 7) {
    // 再次作答
    http
      .post("/Ai/saveEduAiAppointmentReadAgainAnswer", {
        ...params,
        answer: answerData,
      })
      .then((res) => {
        console.log(res);
      });
  }
}

watch(
  () => currentStepStore.currentStep,
  () => {
    if (currentStepStore.currentStep === 2 || currentStepStore.currentStep === 7) {
      checkValues.value = initCheckedValues();
      // 答题的样式和序号回到第一题
      answerClass.value = `answer-index1`;
      answerIndex.value = 1;
      questionIndexClass.value = `index-nav`;
    }

    // 查看解析
    if (currentStepStore.currentStep === 8) {
      const obj = getLocalStorage("GenerateContent", {});
      const firstList = (obj?.answer || ",,,,").split(",");
      const secondList = (obj?.againAnswer || ",,,,").split(",");
      for (let i = 0; i < 5; i++) {
        twiceCheckValues.value[i] = {
          first: firstList[i],
          second: secondList[i],
        };
      }
    }
    if (currentStepStore.currentStep === 9) {
      const list = props.exerciseObj.knowledge.split("\n");
      knowledges.value = list
        .map((item: string, index: number) => {
          const re = /^\s*-\s+(.+)$/;
          if (re.test(item)) {
            return item.replace(re, ($1: string, $2: string) => {
              return `<div style="display: initial;">${$2}<i id="ai-knowledge-audio" data-id="ai-knowledge-audio-${index}" class="iconfont icon-duanlabofang3"></i></div>`;
            });
          } else {
            if (!item) {
              return;
            }
            if (!/[a-zA-Z]+/g.test(item)) {
              return item;
            }
            return `<span>${item}<i id="ai-knowledge-audio" data-id="ai-knowledge-audio-${index}" class="iconfont icon-duanlabofang3"></i></span>`;
          }
        })
        .join("\n")
        .replaceAll("*", "")
        .replace(/[’‘]/g, "'")
        .replace("常用短语：", "");
      knowledges.value = "<span>知识点：\n</span>" + knowledges.value;
      console.log(knowledges.value);
    }
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  glossaryArr.value.push(props.word);
  selectWord.value = props.word;
  type.value = props.type;

  //初始化答题进度
  checkValues.value = initCheckedValues();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ([2, 7].includes(_unref(currentStepStore).currentStep))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkValues.value, (val, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["cursor-pointer", { 'index-spacing': index !== 0 }]),
                    style: _normalizeStyle({
                color: val.value ? 'var(--text-import-color)' : 'var(--font-gray)',
              }),
                    onClick: ($event: any) => (chooseQuestionIndex(index + 1))
                  }, _toDisplayString(index + 1) + "题 ", 15, _hoisted_6))
                }), 128)),
                _createElementVNode("div", {
                  class: _normalizeClass(["index-nav", [
                questionIndexClass.value,
                _unref(currentStepStore).currentStep === 7 ? 'again-nav-index' : '',
              ]])
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_unref(currentStepStore).currentStep === 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "iconfont icon-jishi countdown-img" }, null, -1)),
                      (_unref(currentStepStore).currentStep === 2)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(countdownStore)?.testCountdown ? _unref(countdownStore)?.testCountdown : "00:00"), 1))
                        : _createCommentVNode("", true),
                      ([7].includes(_unref(currentStepStore).currentStep))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "00:00"))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_unref(IonButton), {
                  class: "green_bg text-size",
                  shape: "round",
                  onClick: submitAnswer
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("提交 ")
                  ])),
                  _: 1
                })
              ])
            ]),
            (props.exerciseObj?.question)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["exercise-questions flex-row mt-3", answerClass.value])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(props.exerciseObj?.question), (question, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "question-title flex-column"
                    }, [
                      _createElementVNode("span", _hoisted_11, [
                        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "" }, "【", -1)),
                        _createElementVNode("span", _hoisted_12, _toDisplayString(checkValues.value[index]["value"]), 1),
                        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "" }, "】", -1)),
                        _createTextVNode(" " + _toDisplayString(question.index) + ". " + _toDisplayString(question?.question?.replace(/^\[.+\]/m, "")) + " ", 1),
                        _createElementVNode("span", {
                          class: "question-title_btn",
                          onClick: ($event: any) => (clickTitleBtn(question.reference, props.exerciseObj?.content))
                        }, "查看文章", 8, _hoisted_13)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass([{ 'option-active': checkValues.value[index]['value'] === 'A' }, "question-title_option cursor-pointer mt-1"]),
                        onClick: ($event: any) => (checkQuestionAnswer(+index, 'A'))
                      }, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "display-inline-block mr-3 question-title_option_word" }, "A ", -1)),
                        _createElementVNode("span", null, _toDisplayString(question.a), 1)
                      ], 10, _hoisted_14),
                      _createElementVNode("div", {
                        class: _normalizeClass([{ 'option-active': checkValues.value[index]['value'] === 'B' }, "question-title_option cursor-pointer"]),
                        onClick: ($event: any) => (checkQuestionAnswer(+index, 'B'))
                      }, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "display-inline-block mr-3 question-title_option_word" }, "B ", -1)),
                        _createElementVNode("span", null, _toDisplayString(question.b), 1)
                      ], 10, _hoisted_15),
                      _createElementVNode("div", {
                        class: _normalizeClass([{ 'option-active': checkValues.value[index]['value'] === 'C' }, "question-title_option cursor-pointer"]),
                        onClick: ($event: any) => (checkQuestionAnswer(+index, 'C'))
                      }, [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "display-inline-block mr-3 question-title_option_word" }, "C ", -1)),
                        _createElementVNode("span", null, _toDisplayString(question.c), 1)
                      ], 10, _hoisted_16),
                      _createElementVNode("div", {
                        class: _normalizeClass([{ 'option-active': checkValues.value[index]['value'] === 'D' }, "question-title_option cursor-pointer"]),
                        onClick: ($event: any) => (checkQuestionAnswer(+index, 'D'))
                      }, [
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "display-inline-block mr-3 question-title_option_word" }, "D", -1)),
                        _createElementVNode("span", null, _toDisplayString(question.d), 1)
                      ], 10, _hoisted_17)
                    ]))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      ([8].includes(_unref(currentStepStore).currentStep))
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(props.exerciseObj?.question), (question, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "question-title flex-column mb-5"
                }, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(question?.index ?? question?.result) + ". " + _toDisplayString(/[\u4e00-\u9fa5]+/.test(question?.queType) ? question?.queType : "细节理解题") + "：" + _toDisplayString(question?.question), 1),
                    _createElementVNode("div", null, [
                      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "display-inline-block mr-2" }, "A.", -1)),
                      _createElementVNode("span", null, _toDisplayString(question.a), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "display-inline-block mr-2" }, "B.", -1)),
                      _createElementVNode("span", null, _toDisplayString(question.b), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "display-inline-block mr-2" }, "C.", -1)),
                      _createElementVNode("span", null, _toDisplayString(question.c), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "display-inline-block mr-2" }, "D.", -1)),
                      _createElementVNode("span", null, _toDisplayString(question.d), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(question?.index ?? question?.result) + ".答案 ", 1),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[12] || (_cache[12] = _createTextVNode(" 正确答案：")),
                      _createElementVNode("span", _hoisted_25, "【" + _toDisplayString(question.success) + "】", 1)
                    ]),
                    _createElementVNode("span", _hoisted_26, [
                      _cache[13] || (_cache[13] = _createTextVNode(" 首次答案：")),
                      _createElementVNode("span", {
                        class: _normalizeClass([
                    twiceCheckValues.value[index]['first'] === question.success
                      ? 'common-color'
                      : 'common-red-color',
                  ])
                      }, [
                        _createElementVNode("span", null, "【" + _toDisplayString(twiceCheckValues.value[index]["first"] || "") + "】", 1)
                      ], 2),
                      _cache[14] || (_cache[14] = _createTextVNode(" 二次答案：")),
                      _createElementVNode("span", {
                        class: _normalizeClass([
                    twiceCheckValues.value[index]['second'] === question.success
                      ? 'common-color'
                      : 'common-red-color',
                  ])
                      }, [
                        _createElementVNode("span", null, "【" + _toDisplayString(twiceCheckValues.value[index]["second"] || "") + "】", 1)
                      ], 2)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _cache[15] || (_cache[15] = _createElementVNode("span", { class: "blue-color weight-600 mb-3 mt-3" }, "解析：", -1)),
                    _createElementVNode("span", _hoisted_28, _toDisplayString(question?.analysis), 1)
                  ])
                ])), [
                  [_vShow, props.exerciseObj?.question]
                ])
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(currentStepStore).currentStep === 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createVNode(_unref(IonGrid), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonRow), null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(glossaryStore).glossaryList, (i, index) => {
                        return (_openBlock(), _createBlock(_unref(IonCol), {
                          key: index,
                          size: "auto"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_31, [
                              _createElementVNode("span", null, _toDisplayString(i?.word), 1),
                              _createElementVNode("i", {
                                class: "essay-modal_glossary-delete iconfont icon-guanbi-xiao cursor-pointer",
                                onClick: ($event: any) => (deleteWordFromGlossary(i.id, i))
                              }, null, 8, _hoisted_32)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(currentStepStore).currentStep === 6)
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
            _createVNode(Text, {
              show: true,
              "article-content": textExplain.value
            }, null, 8, ["article-content"])
          ]))
        : _createCommentVNode("", true),
      (_unref(currentStepStore).currentStep === 9)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createVNode(Text, {
              show: true,
              "article-content": knowledges.value,
              onChangeAudioStatus: changeAudioStatus
            }, null, 8, ["article-content"])
          ]))
        : _createCommentVNode("", true),
      (_unref(currentStepStore).currentStep === 5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createVNode(Text, {
              show: true,
              "article-content": props.exerciseObj?.sentence
            }, null, 8, ["article-content"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})