import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/teacher_index/version5/clicked_home_tab.svg'
import _imports_1 from '@/assets/teacher_index/version5/home_tab.svg'
import _imports_2 from '@/assets/teacher_index/version5/clicked_record_tab.svg'
import _imports_3 from '@/assets/teacher_index/version5/record_tab.svg'
import _imports_4 from '@/assets/teacher_index/version5/clicked_student_tab.svg'
import _imports_5 from '@/assets/teacher_index/version5/student_tab.png'
import _imports_6 from '@/assets/teacher_index/version5/clicked_user_tab.svg'
import _imports_7 from '@/assets/teacher_index/version5/user_tab.svg'


const _hoisted_1 = {
  key: 0,
  class: "pic",
  src: _imports_0,
  alt: ""
}
const _hoisted_2 = {
  key: 1,
  class: "pic",
  src: _imports_1,
  alt: ""
}
const _hoisted_3 = {
  key: 0,
  class: "pic",
  src: _imports_2,
  alt: ""
}
const _hoisted_4 = {
  key: 1,
  class: "pic",
  src: _imports_3,
  alt: ""
}
const _hoisted_5 = {
  key: 0,
  class: "pic",
  src: _imports_4,
  alt: ""
}
const _hoisted_6 = {
  key: 1,
  class: "pic",
  src: _imports_5,
  alt: ""
}
const _hoisted_7 = {
  key: 0,
  class: "pic",
  src: _imports_6,
  alt: ""
}
const _hoisted_8 = {
  key: 1,
  class: "pic",
  src: _imports_7,
  alt: ""
}

import { IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/vue";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getBrandImageUrl } from "@/js/brandImages";


export default /*@__PURE__*/_defineComponent({
  __name: 'TeacherTabs',
  setup(__props) {

const currentIndex = ref(0);
const router = useRouter();
const route = useRoute();
const pathList = ["/TeacherIndex", "/ClassRecord", "/StudentList", "/TeacherPersonalIndex"];

function changeBtn(type: number) {
  currentIndex.value = type;
  router.replace(pathList[type]);
}

watch(
  () => route.path,
  (newVal) => {
    currentIndex.value = pathList.indexOf(newVal);
  },
  { deep: true, immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), {
            slot: "bottom",
            class: "bottom-tabbar"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "tab1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (changeBtn(0)))
              }, {
                default: _withCtx(() => [
                  (currentIndex.value === 0)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                    : (_openBlock(), _createElementBlock("img", _hoisted_2)),
                  _createVNode(_unref(IonLabel), {
                    class: _normalizeClass(["label", { active: currentIndex.value === 0 }])
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("首页")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (changeBtn(1)))
              }, {
                default: _withCtx(() => [
                  (currentIndex.value === 1)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                    : (_openBlock(), _createElementBlock("img", _hoisted_4)),
                  _createVNode(_unref(IonLabel), {
                    class: _normalizeClass(["label", { active: currentIndex.value === 1 }])
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("训练记录")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab3",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (changeBtn(2)))
              }, {
                default: _withCtx(() => [
                  (currentIndex.value === 2)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                    : (_openBlock(), _createElementBlock("img", _hoisted_6)),
                  _createVNode(_unref(IonLabel), {
                    class: _normalizeClass(["label", { active: currentIndex.value === 2 }])
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("用户信息")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab4",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (changeBtn(3)))
              }, {
                default: _withCtx(() => [
                  (currentIndex.value === 3)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : (_openBlock(), _createElementBlock("img", _hoisted_8)),
                  _createVNode(_unref(IonLabel), {
                    class: _normalizeClass(["label", { active: currentIndex.value === 3 }])
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("个人中心")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})