/* eslint-disable */

import http from "./http";
import store from "./store";

let heartbeatTimer: any;
const heartbeatInterval = 15000;

function startHeartBeat(socket: any) {
  heartbeatTimer = setInterval(function () {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send("heartbeat");
    }
  }, heartbeatInterval);
}

function stopHeartbeat() {
  clearInterval(heartbeatTimer);
}

export default {
  createWebSocket(userId: number, callback: any) {
    let ws: any = null;
    let that = this;

    //判断当前浏览器是否支持WebSocket
    if ("WebSocket" in window) {
      ws = new WebSocket(http.baseWS + "?userId=" + store.getToken());

      //连接成功建立的回调方法
      ws.onopen = function (event: any) {
        console.log("ws连接成功!");
        // 在连接打开时开始心跳
        //startHeartBeat(ws)
      };

      //接收到消息的回调方法
      ws.onmessage = function (message: any) {
        try {
          let jsonObj = JSON.parse(message.data);
          if (jsonObj) {
            if (jsonObj.command == 201) {
              ws.send(message.data);
              console.log("ws服务端心跳：" + message.data);
            } else {
              callback(jsonObj);
              console.log("ws收到：", jsonObj);
            }
          }
        } catch (e) {
          console.log("解析Websocket消息发生异常，" + message.data);
        }
      };

      //ws连接断开的回调方法
      ws.onclose = function (e: any) {
        console.log("ws连接断开!");

        // 在连接关闭时停止心跳
        //stopHeartbeat()

        setTimeout(() => {
          console.log("ws重连中...");
          that.createWebSocket(userId, callback);
        }, 2000); // 2秒后尝试重新连接
      };

      //连接发生错误的回调方法
      ws.onerror = function () {
        console.log("ws连接错误!");
      };
    } else {
      console.log("当前浏览器不支持websocket！");
    }

    return ws;
  },
};
