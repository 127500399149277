<template>
  <div class="page-background flex-column align-center" :style="props.backgroundColor">
    <div class="page-background_header flex-row align-center justify-between">
      <span class="cursor-pointer" :class="{ 'visibility-hidden': props.title === '学后检测' }"
        ><i class="iconfont icon-back page-background_header-back" @click="routerBack"></i
      ></span>
      <!--没有标题-->
      <div v-if="!hasTitle" class="no-title">
        <slot name="noTitleContent"></slot>
      </div>
      <!--有标题-->
      <div v-if="hasTitle" class="page-background_header-title flex-row align-center">
        <img
          class="page-background_header-bar"
          :src="getBrandImageUrl('page_title_decorator.png')"
          alt=""
        />
        <div class="ion-text-center title-size mr-5 ml-5">
          {{ props.title }}
        </div>
        <img
          class="page-background_header-bar"
          :src="getBrandImageUrl('page_title_decorator.png')"
          alt=""
        />
      </div>
      <div v-if="hasTitle" class="right-bar">
        <div class="right-bar_content">
          <slot name="rightTitle"></slot>
        </div>
      </div>
    </div>
    <div class="page-background_line">
      <img class="line" :src="getBrandImageUrl('background_line.png')" alt="" />
      <img class="decorator1" :src="getBrandImageUrl('background_line_decorator.png')" alt="" />
      <img class="decorator2" :src="getBrandImageUrl('background_line_decorator.png')" alt="" />
    </div>
    <div class="page-background_content" @scroll="scrollEvent">
      <slot></slot>
    </div>
    <div class="page-background_line line-bottom">
      <img class="line" :src="getBrandImageUrl('background_line.png')" alt="" />
      <img class="decorator1" :src="getBrandImageUrl('background_line_decorator.png')" alt="" />
      <img class="decorator2" :src="getBrandImageUrl('background_line_decorator.png')" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDebounceFn, useScroll } from "@vueuse/core";
import { getBrandImageUrl } from "@/js/brandImages";

interface PropsData {
  title: string;
  routerType?: "modal" | "router";
  hasTitle?: string;
  backgroundColor?: Record<string, any>;
}

const props = defineProps<PropsData>();
const router = useRouter();
const emit = defineEmits(["routerBack", "queryList"]);
const isLoad = ref(false);
const hasTitle = ref(true);

function routerBack() {
  if (props.routerType === "modal") {
    emit("routerBack");
    return;
  }
  router.back();
}

const scrollQueryFn = useDebounceFn(() => {
  emit("queryList");
}, 500);

function scrollEvent(e: any) {
  const { directions } = useScroll(e.target);
  if (directions.bottom) {
    scrollQueryFn();
  }
}

onMounted(() => {
  hasTitle.value = props.hasTitle !== "essayGenerate";
});
</script>

<style scoped lang="less">
@import "@/assets/common.less";
@import "@/theme/ai-theme.less";

.page-background {
  position: relative;
  height: 100%;
  width: 100%;
  //padding-bottom: 5vh;
  background-color: var(--page-background-color, var(--font-white));

  .no-title {
    flex: 1;
    width: 100%;
  }

  &_line {
    width: 100%;
    position: relative;
    user-select: none;
    -webkit-user-select: none;

    .line {
      width: 100%;
    }

    .decorator1 {
      width: 2vw;
      position: absolute;
      left: 1%;
      top: 100%;
    }

    .decorator2 {
      width: 2vw;
      position: absolute;
      right: 1%;
      top: 100%;
    }
  }

  .line-bottom {
    margin-top: 2vw;
    transform: rotate(180deg);
  }

  &_bg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }

  &_header {
    width: 100%;
    height: 5%;
    padding: 0.2rem 3vw 0;

    &-title {
      max-width: 60%;

      .title-size {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    &-back {
      height: 3.2vh;
      z-index: 10;
      font-size: 6vw;
      color: var(--text-color, #606266);
    }

    &-bar {
      width: 1.8vh;
      z-index: 10;
      margin-top: 0.5vh;
    }

    .right-bar {
      width: 1.8vh;
      height: 100%;
      position: relative;

      &_content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        vertical-align: middle;
        position: relative;
      }
    }
  }

  &_content {
    width: 100%;
    height: 100%;
    padding: 0 4vw;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
