<script lang="ts" setup>
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useBasicLayout } from "@/hooks/useBasicLayout";
import { showLoading } from "@/views/AIGenerate/openModal";
import { useCurrentStepStore } from "@/hooks/useCurrentStepStore";
import http from "@/js/http";

import { IonContent, IonText } from "@ionic/vue";
import { handleDecodeUrlComponentStr } from "@/views/AIGenerate/smartReading/commonMethod";
import { audioPlayStream } from "@/js/common";
import { useResizeObserver } from "@vueuse/core";

interface Props {
  inversion?: boolean;
  error?: boolean;
  articleContent?: string;
  loading?: boolean;
  asRawText?: boolean;
  propWord?: string;
  show?: boolean;
  learnType?: string;
  fontStyle?: Record<string, any>;
  sentenceTranslate?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["clickSentenceEvent", "changeAudioStatus"]);
const route = useRoute();
const { isMobile } = useBasicLayout();
const currentStepStore = useCurrentStepStore();
const gptStr = ref("");
const studentInfo = ref();

const textRef = ref<HTMLElement>();

const markdownRef = ref<any>();
const textBlack = ref<any>();
const methodsCard = ref<any>();
const fontStyle = ref();

const coordinate = reactive({
  currentX: -100 + "VW",
  currentY: -100 + "VH",
  methodsVisible: false,
  selectionSentence: "",
});
const audioMediaType = ref("");
const openaiAudioPlayer = ref<any>();
const audioStatus = ref("");
const bofangTimer = ref(0);
const bofangIcon = ref("icon-bofang3");
const currentBofangClass = ref("");
const abort = new AbortController();

const textHeight = ref(0);
const emptyHeight = ref(0);
useResizeObserver(markdownRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  console.log(height);
  textHeight.value = height;
  const list = document.querySelectorAll(".ai-article-paragraph");
  list.forEach((item, index) => {
    if (index === 0) {
      emptyHeight.value = item.offsetTop;
    }
  });
});

//调用GPT接口进行单词、句子、段落翻译
async function postGptTranslation(str: string) {
  const requestParams = {
    userKey: "1747892153770205185",
    serverKey: "OPENAI_PROMPT_READARTICLE_TRANSLATE_APP",
    conversationId: new Date().getTime(),
    prompt: str,
    promptParameter: { val1: "", val2: "" },
    options: {},
    usingContext: true,
    topP: 1,
    contentNumber: 10,
  };

  return async (resolve, reject) => {
    const loading = await showLoading("翻译中...");
    const server_url = "http://47.88.90.192:6039/gpt/chatByBot";
    let resData;
    try {
      resData = await http.post(server_url, requestParams);

      if (resData && resData.data && resData.data.data) {
        gptStr.value = resData.data.data.msg;
      }

      await loading.dismiss();
      resolve("");
    } catch (e) {
      await loading.dismiss();
      reject("");
      throw e;
    }
  };
  // 流式获取gpt返回值
  // resData = await fetch(
  //     server_url,
  //     {
  //       method: 'POST',
  //       body: JSON.stringify(requestParams),
  //       headers: {'content-type': 'application/json'},
  //       signal: abort.signal,
  //     })
  // await analysisStreamData(resData)
}

// 解析 stream 流文件
async function analysisStreamData(resData: any) {
  const reader = (resData.body?.getReader() as any) || {};
  const decoder = new TextDecoder();
  const flag = true;
  while (flag) {
    let { done, value } = (await reader?.read()) as any;
    if (done) break;
    const txt = decoder.decode(value);
    gptStr.value += txt;
  }
}

function clickEvent(e: any) {
  emit("clickSentenceEvent", e);
}

const text = computed(() => {
  return props.articleContent;
});

watch(
  () => props.fontStyle,
  () => {
    nextTick(() => {
      fontStyle.value = props.fontStyle;
    });
  },
  { deep: true, immediate: true }
);
watch(audioStatus, () => {
  emit("changeAudioStatus", audioStatus.value);
});

function audioPlay(playText: string) {
  let audioTxt = playText?.replace(/[0-9\u4e00-\u9fa5]/g, "");
  console.log(audioStatus.value);
  if (!audioStatus.value || audioStatus.value === "end") {
    audioPlayStream(audioTxt, (callbackData: any, mediaType: string) => {
      audioMediaType.value = mediaType;
      let num = 0;
      openaiAudioPlayer.value = new Audio(callbackData);
      openaiAudioPlayer.value.oncanplay = function () {
        openaiAudioPlayer.value.play();
      };
      openaiAudioPlayer.value.onended = function () {
        audioStatus.value = "end";
        setElementClass("icon-duanlabofang3");
        clearInterval(bofangTimer.value);
      };
      openaiAudioPlayer.value.oncanplaythrough = () => {
        clearInterval(bofangTimer.value);
        audioStatus.value = "playing";
        bofangTimer.value = window.setInterval(() => {
          num = ++num % 3;
          bofangIcon.value = `icon-duanlabofang${num + 1}`;
          setElementClass(`icon-duanlabofang${num + 1}`);
        }, 300);
      };
    });
  }
}

function setElementClass(value: string) {
  const el = document.querySelector(`[data-id='${currentBofangClass.value}']`);
  const classList = el?.classList;
  classList?.remove("icon-duanlabofang3", "icon-duanlabofang2", "icon-duanlabofang1");
  classList?.add(value);
}

function audioStop(status?: string) {
  if (audioMediaType.value === "MEDIA_SOURCE") {
    if (openaiAudioPlayer.value?.pause) {
      openaiAudioPlayer.value.pause();
      audioStatus.value = status ? status : "end";
    }
  } else if (audioMediaType.value === "AUDIO_CONTEXT") {
    if (openaiAudioPlayer.value) {
      openaiAudioPlayer.value.close();
      audioStatus.value = status ? status : "end";
    }
  }
  setElementClass("icon-duanlabofang3");
  clearInterval(bofangTimer.value);
}

onMounted(() => {
  //获取路由参数
  studentInfo.value = handleDecodeUrlComponentStr(route.params.studentInfo);
  const list = document.querySelectorAll("#ai-knowledge-audio");
  list.forEach((item) => {
    item.addEventListener(
      "click",
      (event) => {
        audioStop();
        currentBofangClass.value = event.target?.dataset?.id;
        audioPlay(event.target?.parentElement.innerText);
      },
      { once: true }
    );
  });
});
onUnmounted(() => {
  audioStop();
  const list = document.querySelectorAll("#ai-knowledge-audio");
  list.forEach((item) => {
    item.removeEventListener("click", (event) => {
      console.log(event.target);
    });
  });
});
//对外部暴露函数
defineExpose({
  markdownRef,
  textBlack,
});
</script>

<template>
  <div class="text-black">
    <div
      v-if="currentStepStore.currentStep === 3 && !props.loading"
      class="line-number"
      :style="{ height: textHeight + 'px' }"
    >
      <div class="line-number-item" :style="{ height: emptyHeight + 'px' }"></div>
      <div v-for="i in 100" :key="i" class="line-number-item">
        {{ i }}
      </div>
    </div>
    <div
      v-if="props.show"
      ref="markdownRef"
      :style="fontStyle"
      class="markdown-body"
      :class="{}"
      style="white-space: pre-line"
      @click="clickEvent"
      v-html="text"
    />
    <div v-else ref="textRef" class="leading-relaxed break-words">
      <div
        v-if="!inversion"
        ref="textBlack"
        :scroll-events="true"
        :overflow-scroll="true"
        :scroll="true"
      >
        <div
          v-if="!asRawText"
          ref="markdownRef"
          class="markdown-body2"
          style="line-height: 3rem"
          v-html="text"
        />
        <ion-text v-else class="whitespace-pre-wrap" v-text="text" />
      </div>
      <ion-text v-else class="whitespace-pre-wrap" v-text="text" />
      <template v-if="loading">
        <span class="dark:text-white w-[4px] h-[20px] block animate-blink" />
      </template>
    </div>
  </div>
</template>

<style lang="less">
@import "@/theme/ai-theme.less";

.line-number {
  overflow: hidden;
  margin-right: 1rem;

  &-item {
    display: block;
    text-align: right;
    line-height: var(line-height, 1.5rem);
    color: #999999;
    font-size: 0.8rem;
  }
}

.ai-article-title {
  text-align: left;
  font-weight: bold;
  text-indent: 0 !important;
  margin-bottom: 0.5rem;
  color: var(--text-title-color, #262626);

  strong {
    color: var(--text-title-color) !important;
  }
}

.ai-article-title-center {
  text-align: center;
  font-weight: bold;
  color: var(--text-title-color, #262626);

  strong {
    color: var(--text-title-color) !important;
  }
}

.ai-illustration {
  width: 100%;
  aspect-ratio: 2 / 1;
  object-fit: cover;
  border-radius: 0.5rem;
}

.ai-article-paragraph {
  margin: var(--paragraph-margin, 0 0 1.5rem 0);
}

.ai-essay-tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 3.5rem;
  font-size: 0.8rem;
  color: var(--text-color, #606266);
  margin-bottom: 0.8rem;

  &__icon {
    margin-right: 0.3rem;
    width: 0.6rem;
  }

  &__count {
    margin-right: 0.1rem;
  }
}

.ai-article-highlight {
  background-color: var(--text-highlight-color, #187ef633);
}

.ai-article-paragraph-active {
  border: 0.1rem dashed var(--text-import-color, #ffad00);
}

#ai-knowledge-audio {
  font-size: 5vw;
  //text-indent: 2vw;
  color: #ffad00;
  margin-left: 2vw;
  vertical-align: middle;
}

.ai-new-word-single {
  background: var(--text-new-word-color, #ffad00);
}

html.plt-mobile ion-app {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

ion-content::part(background) {
  background-color: transparent;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.text-black {
  //position: relative;
  font-size: inherit;
  display: flex;
  width: 100%;
  //padding: 0 0 5vw;

  .cursor-pointer {
    cursor: pointer;
  }

  .handle-methods {
    z-index: 1;
    position: absolute;
    top: var(--currentX);
    left: var(--currentY);
  }
}

.markdown-body {
  background-color: transparent;
  //display: contents;
  width: 100%;
  height: fit-content;
  color: var(--text-color, #606266);
  cursor: pointer;
  font-size: var(--inherit-size);

  &::part(scroll) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  h1 {
    text-align: center;
  }

  p {
    white-space: pre-wrap;
    text-indent: 5vw;
  }

  strong {
    display: inline;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 0;
  }

  pre code,
  pre tt {
    line-height: 1.65;
  }

  .highlight pre,
  pre {
    background-color: #fff;
  }

  code.hljs {
    padding: 0;
  }

  .code-block {
    &-wrapper {
      position: relative;
      padding-top: 24px;
    }

    &-header {
      position: absolute;
      top: 5px;
      right: 0;
      width: 100%;
      padding: 0 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #b3b3b3;

      &__copy {
        cursor: pointer;
        margin-left: 0.5rem;
        user-select: none;

        &:hover {
          color: #65a665;
        }
      }
    }
  }
}

html.dark {
  .message-reply {
    .whitespace-pre-wrap {
      white-space: pre-wrap;
      color: var(--n-text-color);
    }
  }

  .highlight pre,
  pre {
    background-color: #282c34;
  }
}
</style>
