import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-black" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 2,
  class: "dark:text-white w-[4px] h-[20px] block animate-blink"
}

import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useBasicLayout } from "@/hooks/useBasicLayout";
import { showLoading } from "@/views/AIGenerate/openModal";
import { useCurrentStepStore } from "@/hooks/useCurrentStepStore";
import http from "@/js/http";

import { IonContent, IonText } from "@ionic/vue";
import { handleDecodeUrlComponentStr } from "@/views/AIGenerate/smartReading/commonMethod";
import { audioPlayStream } from "@/js/common";
import { useResizeObserver } from "@vueuse/core";

interface Props {
  inversion?: boolean;
  error?: boolean;
  articleContent?: string;
  loading?: boolean;
  asRawText?: boolean;
  propWord?: string;
  show?: boolean;
  learnType?: string;
  fontStyle?: Record<string, any>;
  sentenceTranslate?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MarkdownText',
  props: {
    inversion: { type: Boolean },
    error: { type: Boolean },
    articleContent: {},
    loading: { type: Boolean },
    asRawText: { type: Boolean },
    propWord: {},
    show: { type: Boolean },
    learnType: {},
    fontStyle: {},
    sentenceTranslate: { type: Boolean }
  },
  emits: ["clickSentenceEvent", "changeAudioStatus"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;
const route = useRoute();
const { isMobile } = useBasicLayout();
const currentStepStore = useCurrentStepStore();
const gptStr = ref("");
const studentInfo = ref();

const textRef = ref<HTMLElement>();

const markdownRef = ref<any>();
const textBlack = ref<any>();
const methodsCard = ref<any>();
const fontStyle = ref();

const coordinate = reactive({
  currentX: -100 + "VW",
  currentY: -100 + "VH",
  methodsVisible: false,
  selectionSentence: "",
});
const audioMediaType = ref("");
const openaiAudioPlayer = ref<any>();
const audioStatus = ref("");
const bofangTimer = ref(0);
const bofangIcon = ref("icon-bofang3");
const currentBofangClass = ref("");
const abort = new AbortController();

const textHeight = ref(0);
const emptyHeight = ref(0);
useResizeObserver(markdownRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  console.log(height);
  textHeight.value = height;
  const list = document.querySelectorAll(".ai-article-paragraph");
  list.forEach((item, index) => {
    if (index === 0) {
      emptyHeight.value = item.offsetTop;
    }
  });
});

//调用GPT接口进行单词、句子、段落翻译
async function postGptTranslation(str: string) {
  const requestParams = {
    userKey: "1747892153770205185",
    serverKey: "OPENAI_PROMPT_READARTICLE_TRANSLATE_APP",
    conversationId: new Date().getTime(),
    prompt: str,
    promptParameter: { val1: "", val2: "" },
    options: {},
    usingContext: true,
    topP: 1,
    contentNumber: 10,
  };

  return async (resolve, reject) => {
    const loading = await showLoading("翻译中...");
    const server_url = "http://47.88.90.192:6039/gpt/chatByBot";
    let resData;
    try {
      resData = await http.post(server_url, requestParams);

      if (resData && resData.data && resData.data.data) {
        gptStr.value = resData.data.data.msg;
      }

      await loading.dismiss();
      resolve("");
    } catch (e) {
      await loading.dismiss();
      reject("");
      throw e;
    }
  };
  // 流式获取gpt返回值
  // resData = await fetch(
  //     server_url,
  //     {
  //       method: 'POST',
  //       body: JSON.stringify(requestParams),
  //       headers: {'content-type': 'application/json'},
  //       signal: abort.signal,
  //     })
  // await analysisStreamData(resData)
}

// 解析 stream 流文件
async function analysisStreamData(resData: any) {
  const reader = (resData.body?.getReader() as any) || {};
  const decoder = new TextDecoder();
  const flag = true;
  while (flag) {
    let { done, value } = (await reader?.read()) as any;
    if (done) break;
    const txt = decoder.decode(value);
    gptStr.value += txt;
  }
}

function clickEvent(e: any) {
  emit("clickSentenceEvent", e);
}

const text = computed(() => {
  return props.articleContent;
});

watch(
  () => props.fontStyle,
  () => {
    nextTick(() => {
      fontStyle.value = props.fontStyle;
    });
  },
  { deep: true, immediate: true }
);
watch(audioStatus, () => {
  emit("changeAudioStatus", audioStatus.value);
});

function audioPlay(playText: string) {
  let audioTxt = playText?.replace(/[0-9\u4e00-\u9fa5]/g, "");
  console.log(audioStatus.value);
  if (!audioStatus.value || audioStatus.value === "end") {
    audioPlayStream(audioTxt, (callbackData: any, mediaType: string) => {
      audioMediaType.value = mediaType;
      let num = 0;
      openaiAudioPlayer.value = new Audio(callbackData);
      openaiAudioPlayer.value.oncanplay = function () {
        openaiAudioPlayer.value.play();
      };
      openaiAudioPlayer.value.onended = function () {
        audioStatus.value = "end";
        setElementClass("icon-duanlabofang3");
        clearInterval(bofangTimer.value);
      };
      openaiAudioPlayer.value.oncanplaythrough = () => {
        clearInterval(bofangTimer.value);
        audioStatus.value = "playing";
        bofangTimer.value = window.setInterval(() => {
          num = ++num % 3;
          bofangIcon.value = `icon-duanlabofang${num + 1}`;
          setElementClass(`icon-duanlabofang${num + 1}`);
        }, 300);
      };
    });
  }
}

function setElementClass(value: string) {
  const el = document.querySelector(`[data-id='${currentBofangClass.value}']`);
  const classList = el?.classList;
  classList?.remove("icon-duanlabofang3", "icon-duanlabofang2", "icon-duanlabofang1");
  classList?.add(value);
}

function audioStop(status?: string) {
  if (audioMediaType.value === "MEDIA_SOURCE") {
    if (openaiAudioPlayer.value?.pause) {
      openaiAudioPlayer.value.pause();
      audioStatus.value = status ? status : "end";
    }
  } else if (audioMediaType.value === "AUDIO_CONTEXT") {
    if (openaiAudioPlayer.value) {
      openaiAudioPlayer.value.close();
      audioStatus.value = status ? status : "end";
    }
  }
  setElementClass("icon-duanlabofang3");
  clearInterval(bofangTimer.value);
}

onMounted(() => {
  //获取路由参数
  studentInfo.value = handleDecodeUrlComponentStr(route.params.studentInfo);
  const list = document.querySelectorAll("#ai-knowledge-audio");
  list.forEach((item) => {
    item.addEventListener(
      "click",
      (event) => {
        audioStop();
        currentBofangClass.value = event.target?.dataset?.id;
        audioPlay(event.target?.parentElement.innerText);
      },
      { once: true }
    );
  });
});
onUnmounted(() => {
  audioStop();
  const list = document.querySelectorAll("#ai-knowledge-audio");
  list.forEach((item) => {
    item.removeEventListener("click", (event) => {
      console.log(event.target);
    });
  });
});
//对外部暴露函数
__expose({
  markdownRef,
  textBlack,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentStepStore).currentStep === 3 && !props.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "line-number",
          style: _normalizeStyle({ height: textHeight.value + 'px' })
        }, [
          _createElementVNode("div", {
            class: "line-number-item",
            style: _normalizeStyle({ height: emptyHeight.value + 'px' })
          }, null, 4),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(100, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: "line-number-item"
            }, _toDisplayString(i), 1)
          }), 64))
        ], 4))
      : _createCommentVNode("", true),
    (props.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref_key: "markdownRef",
          ref: markdownRef,
          style: _normalizeStyle([fontStyle.value, {"white-space":"pre-line"}]),
          class: _normalizeClass(["markdown-body", {}]),
          onClick: clickEvent,
          innerHTML: text.value
        }, null, 12, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          ref_key: "textRef",
          ref: textRef,
          class: "leading-relaxed break-words"
        }, [
          (!_ctx.inversion)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "textBlack",
                ref: textBlack,
                "scroll-events": true,
                "overflow-scroll": true,
                scroll: true
              }, [
                (!_ctx.asRawText)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      ref_key: "markdownRef",
                      ref: markdownRef,
                      class: "markdown-body2",
                      style: {"line-height":"3rem"},
                      innerHTML: text.value
                    }, null, 8, _hoisted_3))
                  : (_openBlock(), _createBlock(_unref(IonText), {
                      key: 1,
                      class: "whitespace-pre-wrap",
                      textContent: _toDisplayString(text.value)
                    }, null, 8, ["textContent"]))
              ], 512))
            : (_openBlock(), _createBlock(_unref(IonText), {
                key: 1,
                class: "whitespace-pre-wrap",
                textContent: _toDisplayString(text.value)
              }, null, 8, ["textContent"])),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4))
            : _createCommentVNode("", true)
        ], 512))
  ]))
}
}

})