<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  components: {
    IonApp,
    IonRouterOutlet,
  },
});
</script>

<style lang="less">
@import "@/styles/variables.less";

.img-preview .close {
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: transparent !important;
  color: transparent !important;
}

.modal_controller_class {
  --height: 100%;
  --max-height: 100%;
  --max-width: 100%;
  --min-height: 100%;
  --min-width: 100%;
  --width: 100%;
}

.common_center_modal_class {
  --height: 35rem;
  --max-height: 35rem;
  --min-height: 28rem;
  --max-width: 26rem;
  --min-width: 26rem;
  --width: 26rem;
  --overflow: visible;
  --backdrop-opacity: 0.6 !important;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

/*屏幕下半边框 超出边框部分依旧隐藏*/
.half_modal_controller_class {
  --height: 50%;
  --max-height: 50%;
  --max-width: 100%;
  --min-height: 50%;
  --min-width: 100%;
  --width: 100%;
}

/*屏幕下半边框 超出边框部分依旧显示*/
.half_modal_controller_overflow_visible_class {
  --height: 50%;
  --max-height: 50%;
  --max-width: 100%;
  --min-height: 50%;
  --min-width: 100%;
  --width: 100%;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }
}

.down_modal_class {
  --height: 100%;
  --max-height: 100%;
  --max-width: 100%;
  --min-height: 100%;
  --min-width: 100%;
  --width: 100%;
}

/*带背景的 modal 中心弹框*/
.center_modal_controller_class {
  --height: 50vw;
  --max-height: 50vw;
  --max-width: 50vw;
  --min-height: 50vw;
  --min-width: 50vw;
  --width: 50vw;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 7vw;
    background-image: url("@{base-url}ai_smart_reading/end_exercise_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::part(backdrop) {
    background-color: #00000080;
  }
}

/*带背景的 modal 中心弹框  教练端评价弹窗*/
.center_modal_controller_class2 {
  --height: 441px;
  --max-height: 441px;
  --max-width: 89.33vw;
  /* --min-height: 50VW; */
  --min-width: 89.33vw;
  --width: 89.33vw;
  --overflow: visible;

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
}

/*带背景的 modal 中心弹框  打印资料*/
.center_modal_controller_class3 {
  --height: 230px;
  --max-height: 230px;
  --max-width: 79.47vw;
  --min-height: 230px;
  --min-width: 79.47vw;
  --width: 79.47vw;
  --overflow: visible;
  --backdrop-opacity: 0.32 !important;

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
}

/*带背景的 modal 中心弹框  教练端核对信息*/
.center_modal_controller_class4 {
  --height: 400px;
  --max-height: 400px;
  --max-width: 79.47vw;
  --min-height: 340px;
  --min-width: 79.47vw;
  --width: 79.47vw;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
}

/*不带背景的 modal 中心弹框*/
.common_center_modal_controller_class {
  --height: 50vw;
  --max-height: 50vw;
  --max-width: 50vw;
  --min-height: 50vw;
  --min-width: 50vw;
  --width: 50vw;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 7vw;
  }
}

/*不带背景的 70%宽 modal 中心弹框*/
.common_center_70_modal_controller_class {
  --height: 70vw;
  --max-height: 70vw;
  --max-width: 80vw;
  --min-height: 70vw;
  --min-width: 80vw;
  --width: 80vw;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 7vw;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

/*提示 见 openModal文件 openMsgToast*/
.common_toast_controller_class {
  --background: transparent;
  --box-shadow: none;
  text-align: center;
  --start: auto;
  --end: auto;
  --width: 100%;
  --height: 50%;

  &::part(container) {
    position: relative;
    z-index: 11;
    display: flex;
    overflow: visible;
  }

  &::part(message) {
    padding: 2vw 3vw;
    background-color: rgba(96, 98, 102, 0.8);
    border-radius: 2vw;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 3px;
  }
}

/*不带背景的 70%宽 modal 中心弹框*/
.rectangle_center_modal_controller_class {
  --height: 70vw;
  --max-height: 70vw;
  --max-width: 60vw;
  --min-height: 70vw;
  --min-width: 60vw;
  --width: 60vw;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 7vw;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

span {
  user-select: none;
}

.input input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #ffffff inset !important;
  color: black !important;
  -webkit-text-fill-color: black !important;
  caret-color: black !important;
}

.common_center_modal_controller_class1 {
  --height: 320px;
  --max-height: 320px;
  --max-width: 350px;
  --min-height: 320px;
  --min-width: 350px;
  --width: 350px;
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

.common_center_modal_container_transparent {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 0px;
  --background: transparent;
  --backdrop-opacity: 0;
}

//中心弹框 透明背景 黑色幕布
.common_center_modal_container_transparent1 {
  --height: 70vh;
  --max-height: 70vh;
  --min-height: 70vh;
  --max-width: 23rem;
  --min-width: 23rem;
  --width: 23rem;
  --overflow: visible;
  --backdrop-opacity: 0.6 !important;

  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

.all_modal_transparent {
  --width: 90vw;
  --min-width: 250px;
  --height: 85vh;
  --border-radius: 0px;
  --background: transparent;
  --backdrop-opacity: 0.7 !important;
}
</style>
