<template>
  <ion-page class="protocol-policy">
    <PageWhiteBackground :title="obj.title" router-type="modal" @router-back="routerBack">
      <template #default>
        <div class="protocol-policy_content">{{ obj.content }}</div>
      </template>
    </PageWhiteBackground>
  </ion-page>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, ref } from "vue";
import { IonPage, modalController } from "@ionic/vue";
import PageWhiteBackground from "@/components/PageWhiteBackground.vue";
import { getPrivacy, getUserAgreement } from "@/js/userAgreementContent";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});
const obj = ref({});
onMounted(() => {
  if (localStorage.getItem("STORAGE_KEY_OEM")) {
    let color = JSON.parse(
      localStorage.getItem("STORAGE_KEY_OEM")!
    ).OEM_DETAIL_KEY_BACKGROUND_COLOR;
    document.documentElement.style.setProperty("--brand_background_color", color);
  }
  if (props.type == "userAgreement") {
    obj.value = getUserAgreement();
  } else {
    obj.value = getPrivacy();
  }
});

async function routerBack() {
  await modalController.dismiss();
}
</script>

<style scoped lang="less">
.protocol-policy {
  width: 100%;
  height: 100%;

  &_content {
    width: 100%;
    height: 100%;
    white-space: pre-line;
    line-height: 1.5rem;
  }
}
</style>
