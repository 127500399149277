// const title = JSON.parse(localStorage.getItem('STORAGE_KEY_OEM')!).OEM_DETAIL_KEY_BRAND_NAME
export function getUserAgreement() {
  const title =
    JSON.parse(localStorage.getItem("STORAGE_KEY_OEM") || "{}").OEM_DETAIL_KEY_BRAND_NAME ||
    "李校来啦";
  return {
    title: `${title}用户服务协议`,
    content:
      "" +
      "◆提示条款\n" +
      `欢迎您签署本《用户服务协议》（下称“本协议”）并使用${title}智能语言学习平台！\n` +
      `本协议是由用户（以下简称“您”）与${title}（以下简称“本平台”）之间就注册${title}用户账户以及${title}智能语言学习平台所提供的服务等相关事宜所订立的协议。\n` +
      "【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。\n" +
      `【签约动作】当您阅读并勾选同意本协议并且完成全部注册程序，或者当您在登录过程中勾选“已阅读并同意${title}用户服务协议和隐私政策、法律声明”并登陆本平台的，即表示您已充分阅读、理解并接受本协议的全部内容，并与本平台达成一致，成为本平台“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。\n` +
      "【协议修订】本平台有权在必要时修改本协议条款，并以站内公示的方式通知到您。您可以随时在本平台查阅修改后的最新版本协议。如您不同意修改后的协议，您有权终止对本平台的授权。本协议更新后，如果您继续使用相应权益，即视为您已接受修改后的协议。\n" +
      "如果您未满18周岁，请务必在法定监护人的陪同下阅读本服务协议，并在进行注册等任何行为或使用本平台的其他任何服务前，应事先征得您的法定监护人的同意。\n" +
      "一、协议的主体与范围\n" +
      "1.1 本协议由您与本平台经营者共同缔结，本协议对您与本平台经营者均具有合同效力。\n" +
      "1.2 您应遵守本协议的各项条款，合法合理使用本平台提供的服务，本平台保留在中华人民共和国施行之法律允许的范围内独自决定拒绝服务、关闭您账户、清除或编辑内容的权利。\n" +
      "1.3 由于互联网高速发展，您与本平台签署的本协议列明的条款并不能完整罗列并覆盖您与本平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，本平台法律声明及隐私权政策等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用本平台服务，视为您同意上述补充协议。\n" +
      "二、 账户注册与使用\n" +
      "2.1 用户资格\n" +
      "您确认，在您开始注册程序使用本平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。如虽不具有完全民事权利能力和行为能力，但已经过您的监护人同意并由您的监护人代理注册及使用本平台的各项服务。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。\n" +
      "2.2 账户说明\n" +
      "当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得本平台账户（或称为“使用时长账户”）并成为本平台用户。\n" +
      "本平台账户分为渠道账户（包括全国合伙人、城市服务商、事业部和体验中心）、陪练账户和用户账户（用户账户可分为计时用户账户和创客用户账户），请您在注册时注意区分所注册的账户类型。\n" +
      "本平台只允许每位用户使用一个平台账户。如有证据证明您存在不当注册或不当使用多个平台账户或者将一个账户提供多人使用的情形，本平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给本平台及相关方造成损失的，您还应承担赔偿责任。\n" +
      "您有权使用您设置或确认的账户名、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录本平台。\n" +
      "除您之外的第三方为您注册账户的，则在您为第三方提供手机验证码则视为您同意并授权第三方注册账户。\n" +
      "您的平台账户仅限您本人使用。未经本平台同意，您直接或间接授权第三方使用您本平台账户或获取您账户项下信息的行为无效。如本平台判断您的平台账户的使用可能危及您的账户安全及/或本平台信息安全的，本平台可拒绝提供相应服务或终止本协议。\n" +
      "由于用户账户关联用户信息，除非本平台同意，您的账户不得以任何方式转让。否则本平台有权追究您的违约责任，且由此产生的责任及后果均由您自行承担。\n" +
      "为使您更好地使用本平台的各项服务，保障您的账户安全，本平台有权要求您按本平台的要求及相关法律法规规定完成实名认证。\n" +
      "2.3 注册信息管理\n" +
      "在使用本平台服务时，您应当按本平台页面的提示准确完整地提供您的信息（包括您的姓名、联系电话等）。您了解并同意，您有义务保持您提供信息的真实性及有效性。您设置的账户名/姓名不得违反国家法律法规及本平台相关规则，否则本平台可回收您的账户名。\n" +
      "如您未按平台要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此造成的全部损失与不利后果。\n" +
      "2.4 账户安全规范\n" +
      "您的账户为您自行设置并由您保管，本平台任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开平台。\n" +
      "账户因您泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，本平台并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。\n" +
      "您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买使用时长等商品及服务及披露信息等）负责。\n" +
      "如发现任何未经授权使用您账户登录本平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知我们。您理解本平台对您的任何请求采取行动均需要合理时间，且本平台应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，本平台不承担责任。\n" +
      "三、平台服务及规范\n" +
      "3.1 您可以向渠道经销商购买本平台软件的智能语言系统学习使用时长（以下简称“使用时长”）。\n" +
      `3.2 您向${title}渠道经销商（包括全国合伙人、城市服务商、事业部和体验中心）购买使用时长，需要和渠道经销商达成使用时长订单并向渠道经销商支付相应价款，然后由渠道经销商为您在本平台注册的使用时长账户划拨相应的使用时长。请您仔细确认所购使用时长及服务的时长、价格等信息。如因提交的信息存在错误，由您自行承担相应的责任，与平台无关。\n` +
      "3.3 您购买并获得使用时长后，可以使用本平台软件的智能语言系统。\n" +
      `3.4 您可以向${title}渠道经销商申请购买陪练服务，陪伴、帮助并监督您使用本平台软件的智能语言系统。您向${title}渠道经销商申请购买陪练服务的，需要和渠道经销商达成陪练服务订单并向渠道经销商支付相应陪练服务费，然后由${title}渠道经销商参考您的意见为您选择陪练，并根据您与陪练的时间为您安排智能语言系统学习使用时间。若您对陪练的服务不满意，可以申请${title}渠道经销商为您更换陪练。请您仔细确认所购陪练服务的时长、价格等信息。如因提交的信息存在错误，由您自行承担相应的责任，与平台无关。\n` +
      "3.5 本平台不承诺解决因为用户本身引起的问题，包括且不限于用户电脑软硬件故障，使用不兼容的浏览器，用户所在网络故障，用户的网络接入连接问题等。\n" +
      "3.6 您确认在您下单购买使用时长前，已知悉本平台软件的智能语言系统及其使用时长产品和陪练服务的流程及标准。您同意并确认，在购买并获得使用时长后，不得申请退款。\n" +
      "3.7 服务结束后，您使用本平台软件的智能语言系统的权利将立即终止，本平台没有任何义务为您传送任何未处理的信息或未完成的服务。\n" +
      "3.8 本平台上的使用时长及服务等价格信息可能不定时发生变动，本平台不另行通知。由于互联网技术等客观因素的存在，本平台显示的信息可能存在一定的滞后性或差错，对此您知悉并理解。\n" +
      "3.9 您了解并同意，本平台的使用时长与服务仅依其当前所呈现的状况提供，而且本平台明确地表示拒绝对于“服务”、“资料”或“使用时长”等给予任何明示或暗示之担保或保证，包括但不限于，为商业使用、适合于特定目的或未侵害他人权利之担保或保证等。本平台对于因“服务”、“使用时长”或“资料”等所产生之任何直接、间接、附带的或因此而导致之衍生性损失概不负责。本平台对于任何用户信息或个性化设定之时效、删除、传递错误、未予储存或其它任何问题，均不承担任何责任。\n" +
      "3.10 你明确了解并同意：基于以下原因而造成的，包括但不限于经济、信誉、数据损失或其他无形损失，本平台不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿责任：\n" +
      "（1）为替换从或通过本平台购买或取得之任何使用时长或服务，收到的讯息，或与第三方缔结交易而发生的成本；\n" +
      "（2）你的传输或数据遭到未获授权的存取或改变；\n" +
      "（3）任何第三方在本服务中所作之声明或行为；\n" +
      "（4）第三方以任何方式发布或投递欺诈性信息，或诱导用户受到经济损失；\n" +
      "（5）与本平台相关的其他事宜，但本使用协议有明确规定的除外。\n" +
      "四、个人信息的保护\n" +
      "您在使用平台提供的服务时，同意平台收集、存储、使用、披露和保护您的个人信息。\n" +
      "本平台非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用本平台提供的服务时，您同意本平台按照隐私权政策收集、存储、使用、披露和保护您的个人信息。本平台希望通过隐私权政策向您清楚地介绍本平台对您个人信息的处理方式，因此本平台建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。\n" +
      "五、用户责任承担和处理\n" +
      "5.1您同意按照平台不定时发布、变更和修改的本协议条款及其他规则接受并使用平台的使用时长和服务。使用过程中，您不得通过不正当的手段或其他不公平的手段使用平台的产品和服务或参与平台活动，不得进行包括但不限于攻击、侵入平台的服务器，或破解、修改平台提供的客户端程序等一切干扰平台正常地提供使用时长和服务的活动。\n" +
      "5.2【禁止性信息】您应当确保您所发布的信息不包含以下内容：\n" +
      "（1）违反国家法律法规禁止性规定的；\n" +
      "（2）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；\n" +
      "（3）欺诈、虚假、不准确或存在误导性的；\n" +
      "（4）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；\n" +
      "（5）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；\n" +
      "（6）存在可能破坏、篡改、删除、影响本平台任何系统正常运行或未经授权秘密获取本平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；\n" +
      "（7）其他违背社会公共利益或公共道德或依据相关本平台协议、规则的规定不适合在本平台上发布的。\n" +
      "5.3您若违反本协议或相关法律法规及政策的规定，导致本平台的经营者或其他相关方造受的一切损失（该损失包括但不限于索赔款、违约金以及为解决矛盾而就您的违反行为所支付的赔偿款、律师费、诉讼费、公证费等一切费用），均由您承担。\n" +
      "六、协议的终止\n" +
      "6.1【用户发起的终止】您有权通过以下任一方式终止本协议：\n" +
      "（1）在满足本平台公示的账户注销条件时您通过网站自助服务注销您的账户的；\n" +
      "（2）变更事项生效前您停止使用并明示不愿接受变更事项的；\n" +
      "（3）您明示不愿继续使用本平台服务，且符合本平台终止条件的。\n" +
      "6.2 【本平台发起的终止】出现以下情况时，本平台可以本协议第八条的所列的方式通知您终止本协议：\n" +
      "（1）您违反本协议约定，本平台有权终止本协议的；\n" +
      "（2）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，本平台有权对您的账户予以查封的；\n" +
      "（3）您的账户被本平台依据本协议回收的；\n" +
      "（4）您在本平台有欺诈、发布或销售假冒伪劣/侵权商品、侵犯他人合法权益或其他严重违法违约行为的；\n" +
      "（5）其它应当终止服务的情况。\n" +
      "6.3 协议终止后的处理\n" +
      "本协议终止后，除法律有明确规定外，本平台无义务向您或您指定的第三方披露您账户中的任何信息。本协议终止后，本平台仍享有下列权利：\n" +
      "（1）继续保存您留存于本平台的本协议第五条所列的各类信息；\n" +
      "（2）对于您过往的违约行为，本平台仍可依据本协议向您追究违约责任。\n" +
      "七、免责声明\n" +
      "因互联网技术及无法预知的其他客观因素，本平台无法保证不受干扰或不出现错误，如出现包括但不限于以下情形的，您予以谅解：\n" +
      "（1）地震、台风、洪水、火灾、战争、政府禁令以及其他不能预见并且对其发生和后果不能防止或避免的不可抗力或互联网上的黑客攻击事件，致使影响本服务条款的履行，本平台不承担责任。\n" +
      "（2）在使用本服务的过程中，可能会遇到不可抗力、技术风险等因素，使本服务发生中断，导致合同履行障碍、履行瑕疵、履行延后或履行内容变更、数据丢失等情形，平台在法律允许的最大范围内免责。\n" +
      "（3）您或平台的电脑软件、系统、硬件出现故障或其他原因导致您无法使用平台，本平台不承担责任。\n" +
      "（4）因电力供应故障、通讯网络故障（包括但不限于电子通讯传达失败或延时、用于电子通讯的计算机程序对电子通讯的拦截或操纵）等公共服务因素或您自身因素（包括但不限于您操作不当、通过非平台授权的方式使用本服务）或第三人因素（包括但不限于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏、顾客的错误下单等错误操作），本平台不承担责任。\n" +
      "八、 法律适用、管辖与其他\n" +
      "8.1 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例及/或行业惯例。\n" +
      "8.2 您因使用本平台服务所产生及与本平台服务有关的争议，由本平台与您协商解决。协商不成时，任何一方均可向本平台经营者所在地有管辖权的人民法院提起诉讼。\n" +
      "8.3 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。",
  };
}

export function getPrivacy() {
  const title =
    JSON.parse(localStorage.getItem("STORAGE_KEY_OEM") || "{}").OEM_DETAIL_KEY_BRAND_NAME ||
    "李校来啦";
  return {
    title: `${title}隐私政策`,
    content:
      "" +
      "◆提示条款\n" +
      `欢迎您使用我们（或称“本平台”）的产品和服务！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。基于此，本平台制定本《${title}隐私政策》（下称“本政策”/“本隐私政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。\n` +
      "在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。\n" +
      "您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权。\n" +
      "二、信息收集及使用\n" +
      "在您使用我们的产品/服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：\n" +
      "1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；\n" +
      "2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。\n" +
      "为了向您提供服务，我们会按照如下方式收集、使用您的个人信息：\n" +
      "1、帐号注册信息： 当您首次注册本平台账户时，您需要提供您的手机号码并创建登录密码，上述信息为您注册本平台账户所必需，若您不提供这类信息，您将无法继续使用我们的服务。当您成功注册帐号后，您可以通过手机号码和短信验证码登录本平台，也可通过帐号密码登录本平台。\n" +
      "2、当您使用本平台的学习系统或助教服务时，为了维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集您下述信息：\n" +
      "    （1）设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的 设备相关信息（包括设备型号、操作系统版本、IP地址、设备MAC地址、IMEI、软件版本号等软硬件特征信息）；\n" +
      "（2）日志信息： 我们会收集您对我们服务的详细使用情况，作为有关日志保存，包括接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息。\n" +
      "（3）手机号码：当您使用本平台软件时，徐亚通过手机短信来接收我们服务相关的通知，您需要通过在个人中心绑定您的手机号码，通过短信来接收相关的通知。\n" +
      "3、如我们停止运营本平台，我们将及时停止继续收集您个人信息的活动，将停止运营的通知在至少30日内以逐一送达或通过公告的形式告知您，并对所持有的个人信息进行删除或匿名化处理。\n" +
      "三、授权同意的例外\n" +
      "您充分知晓，根据相关法律法规的要求以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：\n" +
      "（1）与个人信息控制者履行法律法规规定的义务相关的；\n" +
      "（2）与国家安全、国防安全直接相关的；\n" +
      "（3）与公共安全、公共卫生、重大公共利益直接相关的；\n" +
      "（4）与刑事侦查、起诉、审判和判决执行等直接相关的；\n" +
      "（5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；\n" +
      "（6）所涉及的个人信息是个人信息主体自行向社会公众公开的；\n" +
      "（7）根据个人信息主体要求签订和履行合同所必需的；\n" +
      "（8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；\n" +
      "（9）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的 故障；\n" +
      "（10）个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；\n" +
      "（11）个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要， 且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。\n" +
      "三、个人信息的共享、转让和公开披露\n" +
      "（一）共享\n" +
      "我们不会与本平台以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：\n" +
      "1、事先获得您明确的同意或授权；\n" +
      "2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；\n" +
      "3、在您购买渠道经销商的学时或助教服务时，我们会向渠道经销商和助教共享您的电话号码；\n" +
      "4、应您合法需求，协助处理您与他人的纠纷或争议；\n" +
      "5、应您的监护人合法要求而提供您的信息；\n" +
      "6、根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；\n" +
      "7、基于符合法律法规的社会公共利益而使用。\n" +
      "如您在使用过程中对我们的渠道经销商或助教有任何疑问您可以与我们取得联系。我们会及时解答您的疑惑。\n" +
      "（二）转让\n" +
      "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：\n" +
      "1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。\n" +
      "2、在本平台经营者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。\n" +
      "（三）公开披露\n" +
      "我们仅会在以下情况下，才会披露您的信息：\n" +
      "1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；\n" +
      "2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在向相关强制性的行政执法或司法机关披露后且前述机关允许的前提下，我们会及时通过合理的方式通知您。\n" +
      "（四）共享、转让、公开披露个人信息时事先征得授权同意的例外\n" +
      "以下情形中，我们共享、转让、公开披露您的个人信息无需事先征得您的授权同意：\n" +
      "1、与个人信息控制者履行法律法规规定的义务相关的；\n" +
      "2、与国家安全、国防安全直接相关的；\n" +
      "3、与公共安全、公共卫生、重大公共利益直接相关的；\n" +
      "4、与刑事侦查犯罪侦查、起诉、审判和判决执行等直接相关的；\n" +
      "5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n" +
      "6、您自行向社会公众公开的个人信息；\n" +
      "7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。\n" +
      "四、个人信息保存\n" +
      "（一）存储地点\n" +
      "我们在中华人民共和国境内运营本平台中收集和产生的信息，仅存储在中华人民共和国境内。\n" +
      "\n" +
      "（二）本平台与中配（上海）科技有限公司合作，由中配（上海）科技有限公司存储本平台中收集和产生的信息。\n" +
      "（三）存储期限\n" +
      "在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。\n" +
      "（四）个人隐私数据泄露事件处置\n" +
      "在不幸发生个人隐私数据泄露事件后，我们将按照相关法律法规的要求及时向您告知：个人隐私数据泄露的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人隐私数据泄露事件的处置情况。\n" +
      "五、个人信息的管理\n" +
      "我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：\n" +
      "（一）访问、更正\n" +
      "我们鼓励您更新和修改您的信息以使其更准确有效。除法律规定外，您有权访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充。您可以联系我们，申请更正您的信息，我们承诺在15个工作日内完成核查和处理。\n" +
      "在访问、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障您的帐号安全。\n" +
      "（二）删除\n" +
      "在以下情形中，您可以通过联系我们向我们提出删除个人信息的请求：\n" +
      "（1）如果我们处理个人信息的行为违反法律法规；\n" +
      "（2）如果我们收集、使用您的个人信息，却未征得您的授权同意；\n" +
      "（3）如果我们处理个人信息的行为违反了与您的约定；\n" +
      "（4）如果您不再使用我们的产品或服务，或您注销了帐号；\n" +
      "（5）如果我们终止服务及运营。\n" +
      "（三）拒绝、返还、转移、索取数据副本\n" +
      "您可以通过联系我们，向我们提出拒绝、返还、转移、索取个人信息副本的请求。我们承诺在15个工作日内完成核查和处理。\n" +
      "（四）提出异议\n" +
      "若您认为我们对您个人信息的处理违反了本政策或相关法律法规的要求，您可以联系我们提出您的异议。您的每一条宝贵建议我们承诺在30个工作日内完成回复和处理。\n" +
      "（五）注销\n" +
      "在您需要终止使用我们的服务时，您可以申请注销您的帐号。我们承诺在15个工作日内完成核查和处理。\n" +
      "我们在此善意地提醒您，您注销帐号的行为会使您无法继续使用本平台的相关服务，包括合同下载以及出证服务。\n" +
      "注销帐号后您的个人信息会保持不可被检索、访问的状态，我们将不会再使用或对外提供与该帐号相关的个人信息。\n" +
      "六、未成年人信息的保护\n" +
      "本平台的产品和服务主要面向年满十八周岁的成年人，并不向十四周岁以下的未成年人提供服务。本平台承诺尽量在技术手段上进行限制，但我们仅能通过用户提交的信息（比如用户提交的身份证号等）合理地判断其是否为未成年人；当我们合理判断用户为十四周岁以下的未成年人时，本平台会立即删除其个人信息。\n" +
      "七、本政策的更新\n" +
      "（一）为了给您提供更好的服务，本隐私政策也会随之更新。我们会通过在本平台上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问本平台以便及时了解最新的隐私政策。\n" +
      "（二）对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。\n" +
      "本政策所指的重大变更包括但不限于：\n" +
      "\n" +
      "1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；\n" +
      "2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；\n" +
      "3、个人信息共享、转让或公开披露的主要对象发生变化；\n" +
      "4、第三方供应商合作发生变更时；\n" +
      "5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；\n" +
      "6、个人信息安全影响评估报告表明存在高风险时。\n",
  };
}
