<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="bottom-tabbar">
        <ion-tab-button tab="tab1" @click="changeBtn(0)">
          <!-- <ion-icon :icon="homeOutline"/> -->
          <img
            v-if="currentIndex === 0"
            class="pic"
            src="@/assets/teacher_index/version5/clicked_home_tab.svg"
            alt=""
          />
          <img v-else class="pic" src="@/assets/teacher_index/version5/home_tab.svg" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 0 }">首页</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" @click="changeBtn(1)">
          <!-- <ion-icon :icon="timerOutline"/> -->
          <img
            v-if="currentIndex === 1"
            class="pic"
            src="@/assets/teacher_index/version5/clicked_record_tab.svg"
            alt=""
          />
          <img v-else class="pic" src="@/assets/teacher_index/version5/record_tab.svg" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 1 }">训练记录</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" @click="changeBtn(2)">
          <!-- <ion-icon :icon="peopleOutline"/> -->
          <img
            v-if="currentIndex === 2"
            class="pic"
            src="@/assets/teacher_index/version5/clicked_student_tab.svg"
            alt=""
          />
          <img v-else class="pic" src="@/assets/teacher_index/version5/student_tab.png" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 2 }">用户信息</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" @click="changeBtn(3)">
          <img
            v-if="currentIndex === 3"
            class="pic"
            src="@/assets/teacher_index/version5/clicked_user_tab.svg"
            alt=""
          />
          <img v-else class="pic" src="@/assets/teacher_index/version5/user_tab.svg" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 3 }">个人中心</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/vue";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getBrandImageUrl } from "@/js/brandImages";

const currentIndex = ref(0);
const router = useRouter();
const route = useRoute();
const pathList = ["/TeacherIndex", "/ClassRecord", "/StudentList", "/TeacherPersonalIndex"];

function changeBtn(type: number) {
  currentIndex.value = type;
  router.replace(pathList[type]);
}

watch(
  () => route.path,
  (newVal) => {
    currentIndex.value = pathList.indexOf(newVal);
  },
  { deep: true, immediate: true }
);
</script>
<style scoped>
:deep(.bottom-tabbar) {
  height: 15vmin;
  display: flex;
  justify-content: space-around;
}

.pic {
  width: 6vmin;
}

.label {
  color: #868f9e;
}

.active {
  color: #262626;
}

@media screen and (min-width: 600px) {
  .label {
    font-size: 3vmin;
  }
}
</style>
