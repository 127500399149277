import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "page-background_header flex-row align-center justify-between" }
const _hoisted_2 = {
  key: 0,
  class: "no-title"
}
const _hoisted_3 = {
  key: 1,
  class: "page-background_header-title flex-row align-center"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ion-text-center title-size mr-5 ml-5" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "right-bar"
}
const _hoisted_8 = { class: "right-bar_content" }
const _hoisted_9 = { class: "page-background_line" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "page-background_line line-bottom" }
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDebounceFn, useScroll } from "@vueuse/core";
import { getBrandImageUrl } from "@/js/brandImages";

interface PropsData {
  title: string;
  routerType?: "modal" | "router";
  hasTitle?: string;
  backgroundColor?: Record<string, any>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageWhiteBackground',
  props: {
    title: {},
    routerType: {},
    hasTitle: {},
    backgroundColor: {}
  },
  emits: ["routerBack", "queryList"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const router = useRouter();
const emit = __emit;
const isLoad = ref(false);
const hasTitle = ref(true);

function routerBack() {
  if (props.routerType === "modal") {
    emit("routerBack");
    return;
  }
  router.back();
}

const scrollQueryFn = useDebounceFn(() => {
  emit("queryList");
}, 500);

function scrollEvent(e: any) {
  const { directions } = useScroll(e.target);
  if (directions.bottom) {
    scrollQueryFn();
  }
}

onMounted(() => {
  hasTitle.value = props.hasTitle !== "essayGenerate";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "page-background flex-column align-center",
    style: _normalizeStyle(props.backgroundColor)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["cursor-pointer", { 'visibility-hidden': props.title === '学后检测' }])
      }, [
        _createElementVNode("i", {
          class: "iconfont icon-back page-background_header-back",
          onClick: routerBack
        })
      ], 2),
      (!hasTitle.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "noTitleContent")
          ]))
        : _createCommentVNode("", true),
      (hasTitle.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "page-background_header-bar",
              src: _unref(getBrandImageUrl)('page_title_decorator.png'),
              alt: ""
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, _toDisplayString(props.title), 1),
            _createElementVNode("img", {
              class: "page-background_header-bar",
              src: _unref(getBrandImageUrl)('page_title_decorator.png'),
              alt: ""
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      (hasTitle.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "rightTitle")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("img", {
        class: "line",
        src: _unref(getBrandImageUrl)('background_line.png'),
        alt: ""
      }, null, 8, _hoisted_10),
      _createElementVNode("img", {
        class: "decorator1",
        src: _unref(getBrandImageUrl)('background_line_decorator.png'),
        alt: ""
      }, null, 8, _hoisted_11),
      _createElementVNode("img", {
        class: "decorator2",
        src: _unref(getBrandImageUrl)('background_line_decorator.png'),
        alt: ""
      }, null, 8, _hoisted_12)
    ]),
    _createElementVNode("div", {
      class: "page-background_content",
      onScroll: scrollEvent
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 32),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("img", {
        class: "line",
        src: _unref(getBrandImageUrl)('background_line.png'),
        alt: ""
      }, null, 8, _hoisted_14),
      _createElementVNode("img", {
        class: "decorator1",
        src: _unref(getBrandImageUrl)('background_line_decorator.png'),
        alt: ""
      }, null, 8, _hoisted_15),
      _createElementVNode("img", {
        class: "decorator2",
        src: _unref(getBrandImageUrl)('background_line_decorator.png'),
        alt: ""
      }, null, 8, _hoisted_16)
    ])
  ], 4))
}
}

})