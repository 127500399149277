<template>
  <!--添加主题等元素窗口-->
  <div class="essay-modal flex-column">
    <div class="essay-modal_ctt flex-column">
      <!--泛读答题-->
      <div
        v-if="[2, 7].includes(currentStepStore.currentStep)"
        class="essay-modal_exercise flex-column"
      >
        <div class="flex-row justify-between">
          <!--答题序号-->
          <div class="questions-index flex-row align-center">
            <div
              v-for="(val, index) in checkValues"
              :key="index"
              class="cursor-pointer"
              :class="{ 'index-spacing': index !== 0 }"
              :style="{
                color: val.value ? 'var(--text-import-color)' : 'var(--font-gray)',
              }"
              @click="chooseQuestionIndex(index + 1)"
            >
              {{ index + 1 }}题
            </div>
            <div
              class="index-nav"
              :class="[
                questionIndexClass,
                currentStepStore.currentStep === 7 ? 'again-nav-index' : '',
              ]"
            ></div>
          </div>

          <div class="clock-submit">
            <!--答题倒计时-->
            <div v-if="currentStepStore.currentStep === 2" class="clock-submit_countdown mr-2">
              <i class="iconfont icon-jishi countdown-img"></i>
              <span v-if="currentStepStore.currentStep === 2" class="countdown-time common-color">
                {{ countdownStore?.testCountdown ? countdownStore?.testCountdown : "00:00" }}
              </span>
              <span
                v-if="[7].includes(currentStepStore.currentStep)"
                class="countdown-time common-color"
                >00:00</span
              >
            </div>
            <!--提交-->
            <ion-button class="green_bg text-size" shape="round" @click="submitAnswer"
              >提交
            </ion-button>
          </div>
        </div>

        <!--泛读答题 & 再次作答-->
        <div
          v-if="props.exerciseObj?.question"
          class="exercise-questions flex-row mt-3"
          :class="answerClass"
        >
          <div
            v-for="(question, index) in JSON.parse(props.exerciseObj?.question)"
            :key="index"
            class="question-title flex-column"
          >
            <span class="question-title_title weight-700">
              <span class="">【</span>
              <span class="question-title_answer">{{ checkValues[index]["value"] }}</span>
              <span class="">】</span>
              {{ question.index }}. {{ question?.question?.replace(/^\[.+\]/m, "") }}
              <span
                class="question-title_btn"
                @click="clickTitleBtn(question.reference, props.exerciseObj?.content)"
                >查看文章</span
              >
            </span>
            <div
              :class="{ 'option-active': checkValues[index]['value'] === 'A' }"
              class="question-title_option cursor-pointer mt-1"
              @click="checkQuestionAnswer(+index, 'A')"
            >
              <span class="display-inline-block mr-3 question-title_option_word">A </span
              ><span>{{ question.a }}</span>
            </div>
            <div
              :class="{ 'option-active': checkValues[index]['value'] === 'B' }"
              class="question-title_option cursor-pointer"
              @click="checkQuestionAnswer(+index, 'B')"
            >
              <span class="display-inline-block mr-3 question-title_option_word">B </span
              ><span>{{ question.b }}</span>
            </div>
            <div
              :class="{ 'option-active': checkValues[index]['value'] === 'C' }"
              class="question-title_option cursor-pointer"
              @click="checkQuestionAnswer(+index, 'C')"
            >
              <span class="display-inline-block mr-3 question-title_option_word">C </span
              ><span>{{ question.c }}</span>
            </div>
            <div
              :class="{ 'option-active': checkValues[index]['value'] === 'D' }"
              class="question-title_option cursor-pointer"
              @click="checkQuestionAnswer(+index, 'D')"
            >
              <span class="display-inline-block mr-3 question-title_option_word">D</span
              ><span>{{ question.d }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--答案解析-->
      <div
        v-if="[8].includes(currentStepStore.currentStep)"
        class="essay-modal_exercise flex-column"
      >
        <div class="exercise-questions flex-column mt-5">
          <div
            v-for="(question, index) in JSON.parse(props.exerciseObj?.question)"
            v-show="props.exerciseObj?.question"
            :key="index"
            class="question-title flex-column mb-5"
          >
            <div class="question-title_option">
              <span class="question-title_title weight-700"
                >{{ question?.index ?? question?.result }}.
                {{
                  /[\u4e00-\u9fa5]+/.test(question?.queType) ? question?.queType : "细节理解题"
                }}：{{ question?.question }}</span
              >
              <div>
                <span class="display-inline-block mr-2">A.</span><span>{{ question.a }}</span>
              </div>
              <div>
                <span class="display-inline-block mr-2">B.</span><span>{{ question.b }}</span>
              </div>
              <div>
                <span class="display-inline-block mr-2">C.</span><span>{{ question.c }}</span>
              </div>
              <div>
                <span class="display-inline-block mr-2">D.</span><span>{{ question.d }}</span>
              </div>
            </div>
            <div class="exercise-questions-analyze_title weight-600 mb-2">
              {{ question?.index ?? question?.result }}.答案
            </div>
            <div class="flex-row flex-wrap">
              <div class="exercise-questions-analyze_text mr-5">
                正确答案：<span class="common-color">【{{ question.success }}】</span>
              </div>
              <span class="exercise-questions-analyze_text">
                首次答案：<span
                  :class="[
                    twiceCheckValues[index]['first'] === question.success
                      ? 'common-color'
                      : 'common-red-color',
                  ]"
                >
                  <span>【{{ twiceCheckValues[index]["first"] || "" }}】</span>
                </span>
                二次答案：<span
                  :class="[
                    twiceCheckValues[index]['second'] === question.success
                      ? 'common-color'
                      : 'common-red-color',
                  ]"
                >
                  <span>【{{ twiceCheckValues[index]["second"] || "" }}】</span>
                </span>
              </span>
            </div>
            <div class="mt-2">
              <span class="blue-color weight-600 mb-3 mt-3">解析：</span>
              <span class="exercise-questions-analyze_text">{{ question?.analysis }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--挑选生词 & 生词本-->
      <div v-if="currentStepStore.currentStep === 3" class="essay-modal_glossary">
        <div class="essay-modal_glossary-grid">
          <ion-grid>
            <ion-row>
              <ion-col v-for="(i, index) in glossaryStore.glossaryList" :key="index" size="auto">
                <div class="essay-modal_glossary-text text-center">
                  <span>{{ i?.word }}</span>
                  <i
                    class="essay-modal_glossary-delete iconfont icon-guanbi-xiao cursor-pointer"
                    @click="deleteWordFromGlossary(i.id, i)"
                  ></i>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
      <!--全文大意-->
      <div v-if="currentStepStore.currentStep === 6" class="display_content">
        <Text :show="true" :article-content="textExplain" />
      </div>
      <!--知识点-->
      <div v-if="currentStepStore.currentStep === 9" class="display_content">
        <Text :show="true" :article-content="knowledges" @change-audio-status="changeAudioStatus" />
      </div>
      <!--段落大意-->
      <div v-if="currentStepStore.currentStep === 5" class="display_content">
        <Text :show="true" :article-content="props.exerciseObj?.sentence" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import http from "@/js/http";
import { IonButton, IonCol, IonGrid, IonRow, IonSelect, modalController } from "@ionic/vue";
import Text from "@/components/MarkdownText.vue";
import store from "@/js/store";
import { useExerciseCountdownStore } from "@/hooks/useExerciseCountdown";
import { useCurrentStepStore } from "@/hooks/useCurrentStepStore";
import { openMsgToast, openProblematicAlert } from "@/views/AIGenerate/openModal";
import { useGlossaryStore } from "@/hooks/useGlossary";
import { getLocalStorage } from "@/views/AIGenerate/smartReading/commonMethod";
import { useEssayGenerateContentStore } from "@/hooks/useEssayGenerateContentStore";
import { default_exercise_answer } from "@/views/AIGenerate/smartReading/defaultData";
import PageDialog from "@/components/PageDialog.vue";
import { useStorage } from "@vueuse/core/index";

const props = defineProps(["word", "type", "title", "questions", "exerciseObj", "isFullscreen"]);
const emit = defineEmits([
  "closeCurrentHalfModal",
  "openModalFullScreen",
  "closeModalHalfScreen",
  "setQuestionRefer",
  "changeAudioStatus",
]);
const countdownStore = useExerciseCountdownStore();
const contentStore = useEssayGenerateContentStore();
const currentStepStore = useCurrentStepStore();
const glossaryStore = useGlossaryStore();
const route = useRoute();

const type = ref();
const selectWord = ref();
const studentInfo = ref();
const glossaryArr: any = ref([]);
const modalIsFold = ref("expand");
const currentExeValue = ref({ type: "exercise", title: "Questions" });
let answerArr: any = [];
let arr = JSON.parse(JSON.stringify(default_exercise_answer));
const defaultArr = ["", "", "", "", ""];
const answerIndex = ref(1); // 当前答题的序号
const answerClass = ref(""); // 当前答题的样式
const questionIndexClass = ref(""); // 当前答题的样式
const exerciseProcess = ref(0);
const checkValues = ref(default_exercise_answer);
const isFullscreen = ref(false);
const twiceCheckValues = ref<any>([]);
const glossaryList = useStorage("GLOSSARY_ARRAY", [], localStorage);
const knowledges = ref("");

function clickTitleBtn(reference: string, content: number) {
  if (reference) {
    const list = document.querySelectorAll(".ai-article-paragraph");
    reference = reference.replace("一", "1");
    reference = reference.replace("二", "2");
    reference = reference.replace("三", "3");
    reference = reference.replace("四", "4");
    reference = reference.replace("五", "5");
    reference = reference.replace("六", "6");
    reference = reference.replace("七", "7");
    reference = reference.replace("八", "8");
    reference = reference.replace("九", "9");
    reference = reference.replace("十", "10");
    reference = reference.replace("最后1段", `段落${list.length}`);
    console.log(reference);
    const refer = reference.match(/[0-9]+/g);
    emit("setQuestionRefer", refer);
  } else {
    emit("setQuestionRefer");
  }
}

function changeAudioStatus(status: string) {
  emit("changeAudioStatus", status);
}

function openModalFullScreen() {
  emit("openModalFullScreen");
}

function closeModalHalfScreen() {
  emit("closeModalHalfScreen");
}

function initCheckedValues() {
  const obj = contentStore.getStorageData();

  if (!obj) {
    return;
  }

  if (currentStepStore.currentStep === 2) {
    // 泛读答题
    answerArr = obj.answer ? obj.answer.split(",") : defaultArr;
  } else if (currentStepStore.currentStep === 7) {
    // 再次答题
    answerArr = obj.againAnswer ? obj.againAnswer.split(",") : defaultArr;
  }

  arr = arr.map((i: Record<string, any>, index: number) => {
    i["value"] = answerArr[index];
    i["id"] = index + 1;
    return i;
  });
  exerciseProcess.value = (arr || []).filter((item: any) => item.value).length;

  return arr;
}

async function checkQuestionAnswer(idx: number, answer: string) {
  if (checkValues.value[idx]["value"] !== answer) {
    checkValues.value[idx]["value"] = answer;
  } else {
    checkValues.value[idx]["value"] = "";
  }
  exerciseProcess.value = (checkValues.value || []).filter((item: any) => item.value).length;
  console.log(exerciseProcess.value);
  if (checkValues.value[idx]["value"]) {
    if (idx === 4) {
      const modal = await modalController.create({
        component: PageDialog,
        componentProps: {
          message: "是否提交答案并跳转到下一流程？",
          type: "hasCancel",
        },
        cssClass: "center_modal_controller_class3",
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data === "NEXT") {
        await submitAnswer();
      }
    } else {
      chooseQuestionIndex(idx + 2);
    }
  }
}

const textExplain = computed(() => {
  const titleReg = /^(?<=.*)(.*)(?=\s)/g;
  const matchStr = (props.exerciseObj?.textCn || "").match(titleReg) || "";
  /*const content = (props.exerciseObj?.textCn || '').replace(matchStr[0], ($1, $2: any) => {
    return `<strong style="display: inline-block; width: 100%;text-align: center">${matchStr[0] + '\n'}</strong>`;
  })*/

  let content = (props.exerciseObj?.textCn || "").replace(matchStr[0], "");

  content = content
    .replace(/[*]{2}(.+?)[*]{2}/gim, ($1, $2: any) => {
      return `<strong style="color: #0BBB7D;text-align: center">${$2}</strong>`;
    })
    .replace(/^\s+/, "");
  content = "<div>全文大意：</div>" + content;
  return content;
});

async function didDismiss() {
  modalIsFold.value = "fold";
  await modalController.dismiss({
    value: checkValues.value,
    step: currentStepStore.currentStep,
    fold: modalIsFold.value,
  });
}

// 先调取接口,再删除
function deleteWordFromGlossary(id: number, item: Record<string, any>) {
  const appointment = store.getAppointment();
  if (id) {
    http
      .get("/vocabulary/deleteVocabularyPersonal?id=" + id)
      .then((res) => {
        if (res.data.success) {
          openMsgToast("成功删除");
          glossaryStore.getGlossaryList(appointment.studentId, appointment.courseTypeId);
          removeGlossaryFromLocal(item.word);
        } else {
          openMsgToast("删除失败");
        }
      })
      .catch((e) => {
        throw e;
      });
  } else {
    openMsgToast("不合法的单词");
  }
}

function removeGlossaryFromLocal(word: string) {
  let arr: any = [];
  for (let i = 0; i < glossaryList.value.length; i++) {
    if (word !== glossaryList.value[i].word) {
      arr.push(glossaryList.value[i]);
    } else {
      const glossaryDom: any = document.querySelector("#" + glossaryList.value[i].id);
      glossaryDom.style.backgroundColor = "transparent";
    }
  }
  console.log(arr);
  glossaryList.value = arr;
}

async function closeCurrentHalfModal() {
  console.log("关闭弹窗");
  emit("closeCurrentHalfModal");
}

// 修改答案后重新获取AI智阅相关内容
async function getNewGenerateContent() {
  let resData;
  const appointment = store.getAppointment();
  const data = await http.get(`/Ai/queryEduAiAppointment?aptmId=${appointment.aptmId}`);
  if (data?.data.code === 200) {
    resData = data.data.data;
    useEssayGenerateContentStore().storageIdResContent(resData);
  }
  return resData;
}

function chooseQuestionIndex(idx: number) {
  answerIndex.value = idx;
  const question = props.exerciseObj?.question || "[]";
  if (question) {
    answerClass.value = `answer-index${answerIndex.value}`;
    questionIndexClass.value = `index-nav${answerIndex.value}`;
  }
}

function nextTopic() {
  const question = props.exerciseObj?.question || "[]";
  if (question) {
    const len = JSON.parse(question).length;
    if (answerIndex.value <= len) {
      answerIndex.value++;
      answerClass.value = `answer-index${answerIndex.value}`;
      questionIndexClass.value = `index-nav${answerIndex.value}`;
    }
  }
}

function previousTopic() {
  const question = props.exerciseObj?.question || "[]";
  if (question) {
    const len = JSON.parse(question).length;
    if (answerIndex.value <= len) {
      answerIndex.value--;
      answerClass.value = `answer-index${answerIndex.value}`;
      questionIndexClass.value = `index-nav${answerIndex.value}`;
    }
  }
}

// 提交答案
async function submitAnswer() {
  const totalLen = checkValues.value ? checkValues.value.length : 0;
  const fillInputLen = (checkValues.value || []).filter((item: any) => item.value).length;
  if (fillInputLen !== totalLen) {
    const alertParams = {
      message: `剩余${totalLen - fillInputLen}题未作答,是否提交？`,
      buttons: [
        {
          text: "取消",
          role: "cancel",
        },
        {
          text: "确认",
          role: "confirm",
          handler: async () => {
            postAnswerIntoDb();
            countdownStore.terminateCountdownFun(); // 终止倒计时
            await openMsgToast("成功提交答案！");
            await modalController.dismiss().catch((e) => console.log(e));
            await afterSubmitAnswer();
          },
        },
      ],
    };
    await openProblematicAlert(alertParams);
  } else {
    postAnswerIntoDb();
    countdownStore.terminateCountdownFun(); // 终止倒计时
    await openMsgToast("成功提交答案！");
    await modalController.dismiss().catch((e) => console.log(e));
    await afterSubmitAnswer();
  }
}

async function afterSubmitAnswer() {
  await getNewGenerateContent();
  currentStepStore.controlStepData(); // 处理流程执行数据
  currentStepStore.handleProgressTitleNameArr(); // 动态展示流程名称
  await currentStepStore.handleProgressStepEvent(closeCurrentHalfModal); // 处理每个流程相关的事件
}

function postAnswerIntoDb() {
  const answerData = (checkValues.value || []).map((item: any) => item.value).join(",");
  const params = {
    eduAiAppointmentReadId: props.exerciseObj?.eduAiAppointmentReadId,
  };
  if (currentStepStore.currentStep === 2) {
    // 泛读答题
    http
      .post("/Ai/saveThreeEduAiAppointmentRead", {
        ...params,
        answer: answerData,
      })
      .then((res) => {
        console.log(res);
      });
  } else if (currentStepStore.currentStep === 7) {
    // 再次作答
    http
      .post("/Ai/saveEduAiAppointmentReadAgainAnswer", {
        ...params,
        answer: answerData,
      })
      .then((res) => {
        console.log(res);
      });
  }
}

watch(
  () => currentStepStore.currentStep,
  () => {
    if (currentStepStore.currentStep === 2 || currentStepStore.currentStep === 7) {
      checkValues.value = initCheckedValues();
      // 答题的样式和序号回到第一题
      answerClass.value = `answer-index1`;
      answerIndex.value = 1;
      questionIndexClass.value = `index-nav`;
    }

    // 查看解析
    if (currentStepStore.currentStep === 8) {
      const obj = getLocalStorage("GenerateContent", {});
      const firstList = (obj?.answer || ",,,,").split(",");
      const secondList = (obj?.againAnswer || ",,,,").split(",");
      for (let i = 0; i < 5; i++) {
        twiceCheckValues.value[i] = {
          first: firstList[i],
          second: secondList[i],
        };
      }
    }
    if (currentStepStore.currentStep === 9) {
      const list = props.exerciseObj.knowledge.split("\n");
      knowledges.value = list
        .map((item: string, index: number) => {
          const re = /^\s*-\s+(.+)$/;
          if (re.test(item)) {
            return item.replace(re, ($1: string, $2: string) => {
              return `<div style="display: initial;">${$2}<i id="ai-knowledge-audio" data-id="ai-knowledge-audio-${index}" class="iconfont icon-duanlabofang3"></i></div>`;
            });
          } else {
            if (!item) {
              return;
            }
            if (!/[a-zA-Z]+/g.test(item)) {
              return item;
            }
            return `<span>${item}<i id="ai-knowledge-audio" data-id="ai-knowledge-audio-${index}" class="iconfont icon-duanlabofang3"></i></span>`;
          }
        })
        .join("\n")
        .replaceAll("*", "")
        .replace(/[’‘]/g, "'")
        .replace("常用短语：", "");
      knowledges.value = "<span>知识点：\n</span>" + knowledges.value;
      console.log(knowledges.value);
    }
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  glossaryArr.value.push(props.word);
  selectWord.value = props.word;
  type.value = props.type;

  //初始化答题进度
  checkValues.value = initCheckedValues();
});
</script>

<style scoped lang="less">
@import "@/assets/common.less";
@import "@/theme/ai-theme.less";

.modal-header {
  margin-bottom: 2%;

  &_style {
    width: 10%;
    font-weight: 700;
  }

  &_title {
    flex: 1;
    text-align: center;
  }
}

.text-color {
  color: #0bbb7d;
}

ion-content::part(background) {
  background-color: transparent;
}

.essay-modal {
  width: 100%;
  height: 100%;
  padding: 8% 3% 4rem;
  overflow-y: scroll;
  position: relative;
  font-size: var(--inherit-size);

  &_ctt {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &_modify {
    position: absolute;
    bottom: 100%;
    left: 0;
    display: flex;
    width: 100%;
    z-index: 2;
  }

  &_content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  &_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 2.5rem;
  }

  &_exercise {
    flex: 1;
    width: 100%;
    height: calc(100% - 6vw);

    .questions-index {
      position: relative;
      font-size: var(--inherit-size);

      .index-spacing {
        margin-left: 0.9rem;
      }

      .index-nav {
        position: absolute;
        bottom: 23%;
        left: 0;
        width: calc(var(--inherit-size) * 1.6);
        height: 3px;
        border-radius: 1px;
        background-color: var(--text-import-color, #0bbb7d);
        transition: all 0.3s ease;
      }

      .again-nav-index {
        bottom: 7%;
      }

      .index-nav2 {
        left: 21%;
      }

      .index-nav3 {
        left: 42%;
      }

      .index-nav4 {
        left: 64%;
      }

      .index-nav5 {
        left: 85%;
      }
    }

    .green_bg {
      height: 1.8rem;

      &::part(native) {
        font-size: 0.7rem;
        background-color: var(--text-import-color, #0bbb7d);
        --padding-start: 0.8rem;
        --padding-end: 0.8rem;
        --padding-top: 0;
        --padding-bottom: 0;
      }
    }

    .clock-submit {
      position: relative;

      &_countdown {
        .countdown-img {
          margin-bottom: 0.8vw;
          color: var(--icon-color, #262626);
        }

        .countdown-time {
          width: 100%;
          white-space: nowrap;
          font-size: 15px;
          color: var(--text-import-color);
        }
      }
    }

    .exercise-time {
      font-size: 13px;
      height: 13%;
      flex-shrink: 0;

      &_countdown {
        position: relative;
        width: 22%;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .countdown-span {
      white-space: nowrap;
      color: var(--text-model-title-color, #868f9e);

      &-value {
        color: var(--text-import-color, #0bbb7d);
      }
    }

    .exercise-questions {
      flex: 1;
      width: 100%;
      height: 100%;
      line-height: 1.8rem;
      //font-size: 13px;
      transition: transform 0.3s ease;

      .question-title {
        width: 100vw;
        padding-right: 18vw;
        flex-shrink: 0;

        &_title {
          color: var(--text-title-color, #262626);
        }

        &_answer {
          color: var(--mark-orange-color);
        }

        &_btn {
          cursor: pointer;
          font-size: calc(var(--inherit-size, 0.5rem) / 2);
          background-color: var(--text-import-color);
          color: #ffffff;
          border-radius: 1rem;
          padding: 0.3rem 0.6rem;
          margin-left: 0.5rem;
          vertical-align: middle;
          white-space: nowrap;
        }

        &_option {
          line-height: var(--modal-line-height);
          color: var(--text-option-color, var(--font-deep-gray));
          margin-top: 0.5rem;

          &_word {
            font-weight: bold;
          }
        }

        .option-active {
          color: var(--mark-orange-color, var(--mark-border-color));
        }

        .exercise-questions-analyze_title {
          margin-top: 1rem;
          color: var(--text-title-color, #262626);
        }

        .exercise-questions-analyze_text {
          color: var(--text-color, #606266);
        }
      }
    }

    .answer-index1 {
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease;
    }

    .answer-index2 {
      transform: translate3d(-100vw, 0, 0);
      transition: transform 0.3s ease;
    }

    .answer-index3 {
      transform: translate3d(-200vw, 0, 0);
      transition: transform 0.3s ease;
    }

    .answer-index4 {
      transform: translate3d(-300vw, 0, 0);
      transition: transform 0.3s ease;
    }

    .answer-index5 {
      transform: translate3d(-400vw, 0, 0);
      transition: transform 0.3s ease;
    }

    .exercise-inputs {
      height: 18%;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      flex-shrink: 0;

      &_input {
        //padding: 2% 6%;

        ion-input.sc-ion-input-md-h {
          --background: #f5f5f5;
          --placeholder-opacity: 0.8;
          --padding-bottom: 5%;
          --padding-end: 5%;
          --padding-start: 5%;
          --padding-top: 5%;
          border-radius: 2vw;
          border: 1px solid transparent;
          text-align: center;
          font-weight: 700;
          font-size: 20px;
        }

        ion-select {
          width: 6vh;
          height: 6vh;
          border-radius: 2vw;
          text-align: center;
          background-color: var(--bg-color);
          --padding-bottom: 0;
          --padding-top: 0;
          --padding-start: 0;
          --padding-end: 0;

          &::part(icon) {
            width: 0;
            height: 0;
          }
        }

        .exercise-input {
          input {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &_glossary {
    width: 100%;
    height: 100%;

    &-grid {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }

    &-text {
      padding: 2vw 4vw;
      border: 1px solid var(--button-border-color, #d7d7d7);
      position: relative;
      border-radius: 3px;
      background: var(--button-text-background-color, #ffffff);
      color: var(--button-text-color, #606266);

      &:hover {
        color: var(--text-import-color, #2bca9c);
      }
    }

    &-delete {
      position: absolute;
      top: 0;
      right: 0;
      color: var(--text-import-color);
    }
  }

  .display_content {
    width: 100%;
    height: 100%;
    padding-bottom: 10%;
    overflow-y: scroll;
    white-space: pre-line;
  }
}
</style>
